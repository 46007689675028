import React from 'react';
import { Route, Link } from "react-router-dom";
import './faq.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import AddCategoryModal from './AddCategoryModal';

const Faq = () => {
    return (
        <React.Fragment>
            <div className="dk-faqMain">
                <div className="dk-faqHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-faqleft">
                            <input type="text" placeholder="Search..." name="" id="" className="form-control" />
                        </div>
                        <h3 className="title">FAQ - CATEGORY SETTINGS</h3>
                        <div className="dk-faqRight">
                            <Route>
                                <Link to="#"><i class="fas fa-arrows-alt"></i> Reorder</Link>
                                <AddCategoryModal />
                            </Route>
                        </div>
                    </div>
                </div>
                <div className="dk-faqTableMain">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SNo.</th>
                                    <th>Status</th>
                                    <th>Name of Category</th>
                                    <th>Questions</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">1</td>
                                    <td className="text-center">
                                        <div className="dk-faqStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>superadmin1</td>
                                    <td className="text-center">
                                        <Link to="/settings/faq/faq-question" className="dk-faqEdit">
                                            Add/Edit Questions
                                        </Link>
                                    </td>
                                    <td className="text-center">
                                        <div className="dk-faqAction">
                                            <button><i class="far fa-edit"></i></button>
                                            <button><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Faq;
