import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';

const MailboxSubmenu = () => {
    let pathname = window.location.pathname;
    return(
        <React.Fragment>
            <div className="dk-submMenuMain">
                <div className="dk-subMenu">
                    <Route>
                        <ul>
                            <li><Link to="/mailbox/samplepage1" className={`${pathname.match('/samplepage1') ? 'sub-active' : ''}`}>Submenu</Link></li>
                            <li><Link to="/mailbox/samplepage2" className={`${pathname.match('/samplepage2') ? 'sub-active' : ''}`}>Submenu 1</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MailboxSubmenu;
