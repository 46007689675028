import React from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import RecruitementImg from "../../../assets/images/recruitement.png";
import AdvertismentImg from "../../../assets/images/advertisment.png";
import InfoCenterImg from "../../../assets/images/infocenter.png";

const CreateApps = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default CreateApps;
