import React from 'react';
import './style.scss';

const SitePages = () => {
    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default SitePages;
