import React, { useState, useMemo, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import AddNewBkValuesModal from './AddNewBkValuesModal';
import EditBkValuesModal from './EditBkValuesModal';
import { useDispatch, useSelector } from "react-redux";
import { clearState, bkvaluesList, subCategory, subCategory2nd, subCategory3rd, subCategory4th } from "../../../slice/backendValues/";
import './style.scss';

const BkValues = () => {
    const { bkvalueList, mainCatId, subcategory1stList, subcategory2ndList, firstSubCategory, secondSubCategory, thirdSubCategory, subcategory3rdList } = useSelector(state => state.backendValues);
    const [mainCategoryId, setMainCategoryId] = useState(0);
    const [subCategory1st, setSubCategory1st] = useState(0);
    const [subCat2nd, setSubCat2nd] = useState(0);
    const [subCat3rd, setSubCat3rd] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(bkvaluesList({}))
            } catch (err) {
                //dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])

    const get1stSubCategory = async (id) => {
        setMainCategoryId(id)
        await dispatch(subCategory({ categoryId: id }))
    }

    const get2ndSubCategory = async (id) => {
        setSubCategory1st(id)
        await dispatch(subCategory2nd({ categoryId: id }))
    }
    const get3rdSubCategory = async (id) => {
        setSubCat2nd(id)
        await dispatch(subCategory3rd({ categoryId: id }))
        //dispatch(clearState())
    }

    useEffect(() => {
        if (mainCatId != '0') {
            dispatch(subCategory({ categoryId: mainCatId }))
        }
    }, [mainCatId])

    useEffect(() => {
        if (firstSubCategory != '0') {
            setSubCategory1st(firstSubCategory)
            dispatch(subCategory2nd({ categoryId: firstSubCategory }))
        }
    }, [firstSubCategory])

    useEffect(() => {
        if (secondSubCategory != '0') {
            setSubCat2nd(secondSubCategory)
            dispatch(subCategory3rd({ categoryId: secondSubCategory }))
        } else {
            setSubCat2nd(0)
            setSubCat3rd(0)
        }
    }, [secondSubCategory])

    useEffect(() => {
        if (thirdSubCategory != '0') {
            setSubCat3rd(thirdSubCategory)
            dispatch(subCategory4th({ categoryId: thirdSubCategory }))
        }
    }, [thirdSubCategory])

    useEffect(() => {
        (bkvalueList && bkvalueList.length > 0) ?
            setMainCategoryId(bkvalueList[0]._id)
            :
            setMainCategoryId('0')
        setSubCat2nd(0)
        setSubCat3rd(0)
    }, [bkvalueList])

    return (
        <React.Fragment>
            <div className="dk-toolsBkValues">
                <div className="dk-toolsValues-flex">
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <EditBkValuesModal />
                                <h6 className="subTitle">Main Category</h6>
                                <AddNewBkValuesModal parentCate={'0'} />
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {(bkvalueList) ?
                                    bkvalueList.map((item, index) => (
                                        <li>
                                            <div className={(item._id === mainCategoryId) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => get1stSubCategory(item._id)} >{item.name}</div>
                                        </li>
                                    )) : null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <EditBkValuesModal />
                                <h6 className="subTitle">1st Sub Category</h6>
                                <AddNewBkValuesModal parentCate={''} />
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (subcategory1stList) ?
                                        subcategory1stList.map((item, index) => (
                                            <li>
                                                <div className={(item._id === subCategory1st) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => get2ndSubCategory(item._id)} >{item.name}</div>
                                            </li>
                                        )) : null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <EditBkValuesModal />
                                <h6 className="subTitle">2nd Sub Category</h6>
                                <AddNewBkValuesModal />
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (subcategory2ndList) ?
                                        subcategory2ndList.map((item, index) => (
                                            <li>
                                                <div className={(item._id === subCat2nd) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => get3rdSubCategory(item._id)} >{item.name}</div>
                                            </li>
                                        )) : null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <EditBkValuesModal />
                                <h6 className="subTitle">3rd Sub Category</h6>
                                <AddNewBkValuesModal />
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (subcategory3rdList) ?
                                        subcategory3rdList.map((item, index) => (
                                            <li>
                                                <div className={(item._id === subCat3rd) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => get3rdSubCategory(item._id)} >{item.name}</div>
                                            </li>
                                        )) : null
                                }
                            </ul>
                            {/* <ul>
                                <li>
                                    <div className="clickBody">MasterBox</div>
                                </li>
                                <li>
                                    <div className="clickBody">My Account</div>
                                </li>
                                <li>
                                    <div className="clickBody">Test</div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols dk-toolsLastValue ml-3">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to="#"></Link>
                                <h6 className="subTitle">values</h6>
                                <Link to="#" ><i class="fas fa-plus"></i></Link>
                            </div>
                        </div>
                        <div className="dk-toolsValueField">
                            <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#2d9b99', color: '#fff' }} value="DKG Process" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div>
                            {/* <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#286967', color: '#fff' }} value="Client Process" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div>
                            <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#670004', color: '#fff' }} value="Refused Offer" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div>
                            <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#629bd3', color: '#fff' }} value="Job Offer" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div>
                            <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#4768b0', color: '#fff' }} value="Placement" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div>
                            <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#d9714e', color: '#fff' }} value="Credit" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div>
                            <div className="dk-valueFields">
                                <span className="dk-handle"><i class="fas fa-bars"></i></span>
                                <input type="text" style={{ background: '#da3d5e', color: '#fff' }} value="Closed" name="" id="" className="form-control" />
                                <span className="dk-toolsValueDelete"><i class="fas fa-times"></i></span>
                                <span className="dk-toolsValueColor"><i class="fas fa-tint"></i></span>
                            </div> */}

                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
}

export default BkValues;
