import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import SubmitButton from "../../ui/submitButton/index";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../slice/utils/loader/linearSlice";
import { addCategory, clearState } from "../../../slice/backendValues";

import './style.scss';

const AddNewBkValuesModal = ({ parentCate }) => {

  const dispatch = useDispatch();
  const { bkvalueList, isInsert, isError, msg } = useSelector(state => state.backendValues);
  const [show, setShow] = useState(false);
  const [parentCategory, setParentCategory] = useState(parentCate);
  const [categoryName, setCategoryName] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeBtn = (e) => {
    e.preventDefault()
    setShow(false)
    setCategoryName('')
  }
  const submitData = (e) => {
    e.preventDefault()
    if (!categoryName) {
      dispatch(loadingEnd())
      dispatch(showError({ msg: 'Category name is required' }));
    } else {
      setSuccess(false);
      setLoading(true);
      dispatch(loadingStart())
      dispatch(addCategory({ name: categoryName, parentId: '0' }));
    }
  }
  // useEffect(() => {
  //   if (parentCategory === "") {
  //     dispatch(showError({ msg: "Error! Opps... Parent category not selected" }))
  //   }
  // })
  useEffect(() => {
    if (isError) {
      dispatch(showError({ msg: msg }))
      dispatch(loadingEnd())
      dispatch(clearState())
      dispatch(clearLoading())
      setLoading(false);
    }
    if (isInsert) {
      dispatch(showSuccess({ msg: msg }))
      dispatch(loadingEnd())
      dispatch(clearState())
      dispatch(clearLoading())
      dispatch(loadingEnd())
      setLoading(false);
      setCategoryName('')
      setShow(false)
    }
  }, [isError, isInsert])

  return (
    <>
      <Link to="#" onClick={handleShow}><i class="fas fa-plus"></i></Link>

      <Modal className="dk-toolsBkVlueseditModal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Main Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-toolsValuesModalBody">
          <div className="dk-toolsModalbody">
            <form action="">
              <div className="form-group">
                <label htmlFor="">Name of Platform</label>
                <label htmlFor="">
                  <input type="text" placeholder="Enter name..." name="" className="form-control" onChange={(e) => setCategoryName(e.target.value)} value={categoryName} />
                </label>
              </div>
              <div className="form-footer mb-0">
                <button className="closeBtn" onClick={closeBtn}>Close</button>
                {/* <button>Create</button> */}
                <SubmitButton txt="Create" loading={loading} success={success} onClick={submitData} position="justify-content-center" className="dk-addCliBtn" />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNewBkValuesModal;
