import React from 'react';
const CountComment = ({ comment }) => {
    return (
        <React.Fragment>
            {
                comment.filter(value => value.parentId == '0').length
            }
        </React.Fragment>
    )
}
export default CountComment;