import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
const StatusDropdown = ({ id, changeStatus, status }) => {
    let statusClass;
    switch (status) {
        case 'Active':
            statusClass = 'success';
            break;
        case 'Inactive':
            statusClass = 'danger';
            break;
        default:
            statusClass = 'warning';
            break;
    }
    return (
        <React.Fragment>
            <Dropdown className="dk-roleDropdown">
                <Dropdown.Toggle variant={statusClass} id="dropdown-basic">
                    <span>{status}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item id={id} onClick={changeStatus} data-key='Active' >Active</Dropdown.Item>
                    <Dropdown.Item id={id} onClick={changeStatus} data-key='Inactive' >Inactive</Dropdown.Item>
                    <Dropdown.Item id={id} onClick={changeStatus} data-key='Pending' >Pending</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default StatusDropdown;