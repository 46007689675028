import React, { Suspense, useState, useMemo, useEffect } from "react";
import TableLoader from "../../utils/tableLoader/taskTable";
import { Link, Route } from "react-router-dom";
import NoData from "../../utils/tableNoData";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import TablePagination from '../../ui/pagination';
import "./style.scss"
import { emailslist, deleteEmails, clearMessage, clearState, detailsById } from "../../../slice/emails";
import { GlobalFilter } from "../../../components/pages/accounts/filters/GlobalFilter";
import { makeStyles } from '@material-ui/core/styles';
import { showSuccess, showError } from "../../../slice/utils/message/messageSlice";
import DeleteModal from "../../ui/delete/DeleteModal";
import { loadingStart, loadingEnd, clearLoading } from "../../../slice/utils/loader/linearSlice";
import Authentication from "../../helpers/authenticate";
import EmailReorderModal from "./EmailReorderModal";
import AddNewEmailsModal from './AddNewEmailsModal';
import EditEmailsModal from './EditEmailsModal';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));
function Table({ loading, columns, data }) {

    const classes = useStyles();
    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')

    const [selectedOptions, setSelectedOptions] = useState('');
    const [isReset, setIsReset] = useState(false)

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'mangerId') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: [""]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    const resetIsClear = () => {
        setIsReset(false)
    }
    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        setSelectedOptions(value)
        setFilter(name, value)
    }
    const [show, setShow] = useState(false);
    const [showReoderPopup, setShowReoderPopup] = useState(false)

    const handleShow = () => {
        setShowReoderPopup(true)
    }
    const handleClose = () => setShowReoderPopup(false);

    return (
        <React.Fragment>
            <Authentication />

            {showReoderPopup ? <EmailReorderModal showPopup={false} hidePopup={handleClose} /> : null}

            <div className="dk-toolsMain">
                <div className="dk-toolsHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-toolsLeft">
                            <div className="dk-toolsSearch">
                                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} reset={isReset} clearReset={resetIsClear} className="form-control" />
                            </div>
                        </div>
                        <h3 className="title">BUSINESS EMAILS LIST</h3>
                        <div className="dk-toolsRight">
                            <Route>
                                <Link to="#" onClick={handleShow}><i class="fas fa-arrows-alt"></i> Reorder</Link>
                                <AddNewEmailsModal />
                            </Route>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dk-clientsTable-main">
                <div className="table-responsive">
                    <div className="dk-smsTableHead">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-smsTableHead-right">

                            </div>

                        </div>
                    </div>
                    <div className="table-reposive">
                        <table className="table table-bordered dk-toolsloginsTable" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="6" txt="No data found..." /> : null}
                            {
                                (loading) ? <TableLoader colspan="18" /> :
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(
                                            (row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                            }
                        </table>
                    </div>
                </div>
                <div className="dk-tablePagination">
                    <div className="d-flex align-items-center justify-content-between">
                        <span>Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</span>
                        <div className={classes.root}>
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

const AccountClients = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth)
    const { emails, isLoading, isUpdate, isError, isDelete, msg, isInsert } = useSelector(state => state.emails);
    const [userId, setUserId] = useState(userInfo.id);
    const [data, setData] = useState([]);
    const [rowId, setRowId] = useState('');
    //const [show, setShow] = useState(false);
    const [showAccountDelete, setLoginsDelete] = useState(false)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const deleteItem = async () => {

    }
    const deleteRow = async () => {
        await dispatch(deleteEmails({ id: rowId }))
    }
    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(emailslist({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!isLoading && emails && emails.length > -1) {
            setData(emails)
        }
        if (!isUpdate && emails && emails.length > -1) {
            setData(emails)
        }
        setData(emails)
    }, [isLoading, isUpdate, isInsert, isDelete])

    const columns = useMemo(() => [
        {
            Header: 'SNO.',
            title: 'SNO.',
            Cell: (row) => Number(row.row.id) + 1,
            accessor: 'number'
        },

        {
            Header: 'Name of Email Address',
            title: 'Name of Email Address',
            accessor: 'email',
            Cell: (row) => {
                return (<>
                    {row.row.original.email}
                </>
                )
            }
        },
        {
            Header: 'Category of Email',
            title: 'Category of Email',
            accessor: 'category',
            Cell: (row) => {
                return (
                    <>
                        {row.row.original.category}
                    </>
                )
            }
        },

        {
            Header: 'Response Redirecting to',
            title: 'Response Redirecting to',
            accessor: 'url',
            Cell: (row) => {
                return (
                    <>
                        {row.row.original.url}
                    </>
                )
            }
        },
        {
            Header: 'Password',
            title: 'Password',
            accessor: 'passcode',
            Cell: (row) => {
                return (<>
                    {row.row.original.passcode}
                </>
                )
            }
        },
        {
            Header: 'Action',
            title: 'Action',
            Cell: ({ cell }) => {
                return (<>
                    <div className="dk-toolsTable-actions">
                        <Route>
                            <Link to="#" onClick={() => showEditModal(cell.row.original._id)} className="dk-tableEditBtn mr-2"><i class="far fa-edit"></i></Link>
                            <Link to="#" className="dk-tableDeleteBtn" onClick={() => deleteAccount(cell.row.original._id)}><i class="far fa-trash-alt"></i></Link>
                        </Route>
                    </div>
                </>
                )
            }
        }
    ], [])

    const [show, setShow] = useState(false);
    const [showReoderPopup, setShowReoderPopup] = useState(false)
    const [showEditPopup, setShowEditPopup] = useState(false)
    const [emailRowId, setEmailRowId] = useState('0')

    const showEditModal = async (rowId) => {
        setEmailRowId(rowId)
        await dispatch(detailsById({ rowId: rowId }))
        setShowEditPopup(true)
    }

    const handleClose = () => {
        setShowReoderPopup(false);
    }

    const handleEditClose = () => {
        setShowEditPopup(false);
    }

    const [showDelete, setShowDelete] = useState(false)
    const deleteAccount = (id) => {
        setRowId(id)
        setLoginsDelete(true)
    }

    const handleCloseDelete = () => {
        setShowDelete(false)
        setLoginsDelete(false)
    }

    useEffect(() => {
        if (isInsert) {
            // dispatch(showSuccess({ msg: msg }))
            // dispatch(loadingEnd())
            // dispatch(clearState())
            // dispatch(clearLoading())
            // dispatch(loadingEnd())
            // setLoading(false);
            // setShow(false)
        }
    }, [isInsert])

    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setLoginsDelete(false)
            setSuccess(true);
            setLoading(false);
            dispatch(clearState())
            dispatch(clearMessage())
        }
    }, [isDelete])
    return (
        <React.Fragment>
            {
                (showEditPopup) ? <EditEmailsModal emailId={rowId} handleClose={handleEditClose} /> : null
            }
            {
                (showAccountDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteRow} /> : null
            }
            {
                (showDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteItem} /> : null
            }
            <div className="dk-clientsMain dk-loginsPageCon">
                <Table
                    data={data}
                    columns={columns}
                    loading={isLoading}
                />
            </div>
        </React.Fragment>
    );
}

export default AccountClients;
