import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './faq.scss';

const AddCategoryModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Link to="#" onClick={handleShow}><i class="fas fa-plus"></i> Add Category</Link>

            <Modal className="dk-faqAddCategoryMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>EDIT CATEGORY</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-faqAddModal-body">
                        <form action="">
                            <div className="form-group">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="">Category Name *</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="" style={{ width: '148px' }}>Sequence *</label>
                                    <label htmlFor="" style={{ width: '220px' }}>
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="form-group text-center mt-4">
                                <button>Save</button>
                            </div>

                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddCategoryModal;
