import React, { Suspense, useState, useMemo, useEffect } from "react";
import TableLoader from "../../../utils/tableLoader/taskTable";
import { Link, Route } from "react-router-dom";
import NoData from "../../../utils/tableNoData";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import TablePagination from '../../../ui/pagination';
//import './clients.scss';
import StatusDropdown from '../../../ui/statusDropdown';
import { clientuserlist, clearMessage, updateStatus, deleteClientUserAccount } from "../../../../slice/clientUsers/";
import DateFormat from '../../../utils/dateFormats/DateFormat';
import { GlobalFilter } from "../filters/GlobalFilter";
import { makeStyles } from '@material-ui/core/styles';
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import DeleteModal from "../../../ui/delete/DeleteModal";
import FilterSelectInstance from '../../../ui/selectPicker/FilterSelectInstance';
import Authentication from "../../../helpers/authenticate";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));
function Table({ loading, columns, data }) {

    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState('');
    const [isReset, setIsReset] = useState(false)

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["mangerId"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    const clearAllFilter = () => {
        setFilter('mangerId', '')
        setFilter('status', '')
        setGlobalFilter('')
        setIsReset(true);
        setSelectedOptions('')
    }

    const resetIsClear = () => {
        setIsReset(false)
    }

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        setSelectedOptions(value)
        setFilter(name, value)
    }

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-clientsHead">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-clientsHead-leftPanel">
                        <div className="dk-searchCols">
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} reset={isReset} clearReset={resetIsClear} className="form-control" />
                        </div>
                        <div className="dk-clientLeft-btn dk-accountManeger">
                            <Link to="#" className="filterbtn" onClick={() => clearAllFilter()}><i class="fas fa-sync-alt"></i></Link>
                        </div>
                    </div>
                    <h3 className="title">USERS ACCOUNT</h3>
                    <div className="dk-clientsHead-rightPanel">
                        <div className="d-flex align-items-center">
                            <div className="dk-accountSmallBtn">
                                <Link to="#"><i class="fas fa-search"></i></Link>
                            </div>
                            <div className="dk-accountRightPanel-dropdown">
                                <FilterSelectInstance list={tableInstance.flatRows} type="status" placeholder="Status" name="status" onChange={handleChangeSelect} reset={isReset} clearReset={resetIsClear} selectedOptions={selectedOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dk-clientsTable-main">
                <div className="table-responsive">
                    <div className="dk-smsTableHead">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-smsTableHead-right">

                            </div>

                        </div>
                    </div>
                    <div className="table-reposive">
                        <table className="table table-bordered" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="11" txt="No data found..." /> : null}
                            {
                                (loading) ? <TableLoader colspan="18" /> :
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(
                                            (row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                            }
                        </table>
                    </div>
                </div>
                <div className="dk-tablePagination">
                    <div className="d-flex align-items-center justify-content-between">
                        <span>Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</span>
                        <div className={classes.root}>
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const AccountUsers = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth)
    const { userList, isLoading, isUpdate, isError, isDelete, errorMessage, isCommentLoading, comments, isSuccess, msg, users } = useSelector(state => state.clientUsers);
    const [userId, setUserId] = useState(userInfo.id);
    const [data, setData] = useState([]);
    const [rowId, setRowId] = useState('');

    /** Show Comments */
    const [showComment, setShowComment] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [btnTxt, setBtnTxt] = useState('Submit');

    const handleCloseComment = () => setShowComment(false);

    useEffect(() => {
        if (!isCommentLoading) {
            setCommentList(comments)
        }
    }, [isCommentLoading])
    /** End Show Comments */

    /** Insert Comment */
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [parentId, setParentId] = useState('0');
    const [comment, setComment] = useState('');

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "comment":
                setComment(value)
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            setSuccess(true);
            setLoading(false);
            dispatch(clearMessage());
        }
        if (isSuccess) {
            dispatch(showSuccess({ msg: msg }))
            setSuccess(true);
            setLoading(false);
            setComment('');
            dispatch(clearMessage());
        }

    }, [isError, isSuccess])
    /** End Insert Comment */
    const [showAccountDelete, setAccountDelete] = useState(false)
    /*Delete Comment */
    const [showDelete, setShowDelete] = useState(false)
    const [commentId, setCommentId] = useState('0')

    const deleteData = async (id) => {
        setCommentId(id)
        setShowDelete(true)
    }
    const handleCloseDelete = () => {
        setShowDelete(false)
        setAccountDelete(false)
    }
    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setAccountDelete(false)
            setCommentList(comments)
            setSuccess(true);
            setLoading(false);
            setCommentId('0');
            dispatch(clearMessage())
        }
    }, [isDelete])
    /*End Delete Comment*/

    const deleteRow = async () => {
        await dispatch(deleteClientUserAccount({ id: rowId }))
    }
    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(clientuserlist({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    const changeStatus = async (e) => {
        await dispatch(updateStatus({ rowId: e.target.id, status: e.target.dataset.key }))
    }
    useEffect(() => {
        if (!isLoading && userList && userList.length > -1) {
            setData(userList)
        }
        if (!isUpdate && userList && userList.length > -1) {
            setData(userList)
        }
        setData(userList)
    }, [isLoading, isUpdate, changeStatus])

    const columns = useMemo(() => [
        {
            Header: 'SNO.',
            title: 'SNO.',
            Cell: (row) => Number(row.row.id) + 1,
            accessor: 'number'
        },
        {
            Header: 'Status',
            title: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => (<>
                <StatusDropdown id={cell.row.original._id} changeStatus={changeStatus} status={cell.row.original.status} />
            </>)
        },
        {
            Header: 'Created',
            title: 'Created',
            accessor: 'createdAt',
            Cell: (row) => (<DateFormat date={row.row.original.createdAt} />)
        },
        {
            id: 'mangerId',
            Header: 'ID',
            title: 'ID',
            accessor: 'accountManager',
            show: false,
        },
        {
            Header: 'User ID',
            title: 'User ID',
            accessor: 'accountManager',
            Cell: (row) => {
                return (<>
                    {row.row.original.uid}
                </>
                )
            }
        },
        {
            Header: 'User Type',
            title: 'User Type',
            accessor: 'role',
            Cell: (row) => {
                return (<>
                    {row.row.original.role
                    }
                </>
                )
            }
        },
        {
            Header: 'Name of User',
            title: 'Name of User',
            accessor: 'firstName',
            Cell: (row) => {
                return (<>
                    {row.row.original.firstName + ' ' + row.row.original.lastName}
                </>
                )
            }
        },
        {
            Header: 'Email Address',
            title: 'Email Address',
            accessor: 'email',
            Cell: (row) => {
                return (
                    <>
                        {row.row.original.email}
                    </>
                )
            }
        },
        {
            Header: 'City/Town',
            title: 'City/Town',
            accessor: 'city',
            Cell: (row) => {
                return (<>
                    {row.row.original.city}
                </>
                )
            }
        },
        {
            Header: 'Country',
            title: 'Country',
            accessor: 'country',
            Cell: (row) => {
                return (<>
                    {row.row.original.country}
                </>
                )
            }
        },
        {
            Header: 'Name of Company',
            title: 'Name of Company',
            accessor: 'companyId.businessName',
            Cell: (row) => {
                return (<>
                    {row.row.original.companyId.businessName}
                </>
                )
            }
        },
        {
            Header: 'Action',
            title: 'Action',
            Cell: ({ cell }) => {
                return (<>
                    <div className="dk-actionBtn">
                        <Route>
                            <Link to="#" className="dk-viewBtn"><i class="fas fa-external-link-alt"></i></Link>
                            <Link to="#" className="dk-editBtn"><i class="far fa-edit"></i></Link>
                            <Link to="#" className="dk-deleteBtn" onClick={() => deleteAccount(cell.row.original._id)}><i class="far fa-trash-alt"></i></Link>
                        </Route>
                    </div>
                </>
                )
            }
        }
    ], [])

    const deleteAccount = (id) => {
        setRowId(id)
        setAccountDelete(true)
    }
    return (
        <React.Fragment>
            {
                (showAccountDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteRow} /> : null
            }

            <div className="dk-clientsMain">
                <Table
                    data={data}
                    columns={columns}
                    loading={isLoading}
                />
            </div>
        </React.Fragment>
    );
}

export default AccountUsers;
