import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';

const AccountsSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-submMenuMain">
                <div className="dk-subMenu">
                    <Route>
                        <ul>
                            <li><Link to="/accounts/samplepage1" className={`${pathname.match('/samplepage1') ? 'sub-active' : ''}`}>Summary</Link></li>
                            <li><Link to="/accounts/companies" className={`${pathname.match('/accounts/companies') ? 'sub-active' : ''}`}>Companies</Link></li>
                            <li><Link to="/accounts/users" className={`${pathname.match('/accounts/users') ? 'sub-active' : ''}`}>Users</Link></li>
                            <li><Link to="/accounts/add-client/step-1" className={`${pathname.match('/accounts/add-client') ? 'sub-active' : ''}`}>Add Client</Link></li>
                            <li><Link to="/accounts/samplepage5" className={`${pathname.match('/samplepage5') ? 'sub-active' : ''}`}>Settings</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AccountsSubmenu;
