import React from 'react';
import { Route, Link } from "react-router-dom";

const LeftSideMenu = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <li><Link to="/apps/custom-apps" className={`${pathname.match('/apps/custom-apps') ? 'active' : ''}`}>Recruitment</Link></li>
                <li><Link to="/apps/restaurants" className={`${pathname.match('/apps/restaurants') ? 'active' : ''}`}>Restaurants</Link></li>
                <li><Link to="/apps/dance-fitness" className={`${pathname.match('/apps/dance-fitness') ? 'active' : ''}`}>Dance & Fitness</Link></li>
                <li><Link to="/apps/retail-business" className={`${pathname.match('/apps/retail-business') ? 'active' : ''}`}>Retail Business</Link></li>
                <li><Link to="/apps/self-employed" className={`${pathname.match('/apps/self-employed') ? 'active' : ''}`}>Self Employed</Link></li>
            </ul>
        </React.Fragment>
    );
}

export default LeftSideMenu;
