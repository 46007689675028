import React, { useState, useEffect } from "react";
import { updateDetails, clearState } from "../../../slice/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { updateHeaderDetails } from "../../../slice/auth/authSlice";
import SubmitButton from "../../ui/submitButton";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileBase64 from 'react-file-base64';
// import * as MyConstant from "../../../constants";
// const IMG_CLOUD_BASE_URL = MyConstant.IMG_CLOUD_BASE_URL;
const UserConstants = require('../../../constants')

const UpdateUserDetails = () => {
    const { userDetails, isUpdate, msg, isError } = useSelector(state => state.userList);
    const { userInfo } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [userId, setUserId] = useState(userDetails._id);
    const [userName, setUserName] = useState(userDetails.firstName + ' ' + userDetails.lastName);
    const [role, setRole] = useState(userDetails.role);
    const [currentCountry, setCurrentCountry] = useState(userDetails.country);
    const [currentCity, setCurrentCity] = useState(userDetails.city);
    const [nationality, setNationality] = useState(userDetails.nationality);
    const [birthDate, setBirthDate] = useState(userDetails.birthDate);
    const [email, setEmail] = useState(userDetails.email);
    const [backupEmail, setBackupEmail] = useState(userDetails.backupEmail);
    const [mobileNo, setMobileNo] = useState(userDetails.mobileNo);
    const [landlineNo, setLandlineNo] = useState(userDetails.landlineNo);
    const [skypeId, setSkypeId] = useState(userDetails.skypeId);
    const [whatsApp, setWhatsapp] = useState(userDetails.whatsApp);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [file, setFile] = useState(userDetails.userImg);
    const [userImg, setUserImg] = useState(UserConstants.IMG_CLOUD_BASE_URL.Url + userDetails.userImg);
    const defaultSrc = UserConstants.IMG_CLOUD_BASE_URL.Url + "erp/admin/users/default_user.png";


    useEffect(() => {
        if (userDetails.userImg != 'admin/users/default_user.png') {
            setFile(UserConstants.IMG_CLOUD_BASE_URL.Url + userDetails.userImg)
        }
    }, [])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const submitUserDetails = (e) => {
        e.preventDefault()
        setSuccess(false);
        setLoading(true);

        const firstName = userName.split(" ")[0];
        const lName = userName.split(" ")[1] + " " + userName.split(" ")[2];
        const country = currentCountry;
        const city = currentCity;

        if (!firstName || !role || !email) {
            dispatch(showError({ msg: 'All fields required' }))
            setLoading(false);
        } else {
            setLoading(true);
            if (password != "" || confirmPassword != "") {
                if (password != confirmPassword) {
                    dispatch(showError({ msg: 'Password not confirm password not matched' }))
                    setLoading(false);
                } else {
                    dispatch(updateDetails({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, userImg }))
                }
            } else {
                dispatch(updateDetails({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, userImg }))
            }
        }
    }


    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: msg }))
            setLoading(false);
            setSuccess(false);
        }
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            if (userInfo.id === userDetails._id) {
                dispatch(updateHeaderDetails({ name: userDetails.firstName + " " + userDetails.lastName, userImg: userDetails.userImg }))
            }
            setLoading(false);
            setSuccess(false);
        }
    }, [isError, isUpdate])
    const getFiles = (e) => {
        setFile(e.base64)
    }

    return (
        <>
            <div className="dk-userEditMain">
                <div className="admin-edit-profile">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center profile-photo">
                            <figure id="edit_image">
                                <img className="previewImglass" src={file ? file : defaultSrc} alt="preview" className="img-fluid" />
                            </figure>

                            <div className="dk-userChoosFile">
                                <FileBase64
                                    multiple={false}
                                    onDone={getFiles}
                                />
                                <span>Change Photo</span>
                            </div>

                        </div>
                    </div>

                    <div className="dk-userEditForm">
                        <form action="">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Full Name</label>
                                        <label htmlFor="">
                                            <input type="text" value={userName} name="" id="" className="form-control" onChange={(e) => setUserName(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Role</label>
                                        <label htmlFor="">
                                            <input type="text" value={role} name="" id="" className="form-control" readOnly />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Current Country</label>
                                        <label htmlFor="">
                                            <input type="text" value={currentCountry} name="" id="" className="form-control" onChange={(e) => setCurrentCountry(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Current City</label>
                                        <label htmlFor="">
                                            <input type="text" value={currentCity} name="" id="" className="form-control" onChange={(e) => setCurrentCity(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Nationality - Optional</label>
                                        <label htmlFor="">
                                            <input type="text" value={nationality} name="" id="" className="form-control" onChange={(e) => setNationality(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Date of Birth</label>
                                        <label htmlFor="" className="dk-userDate">
                                            <input type="text" value={birthDate} name="" id="" className="form-control" onChange={(e) => setBirthDate(e.target.value)} />
                                            <span><i class="far fa-calendar-alt"></i></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Primary Email Address</label>
                                        <label htmlFor="">
                                            <input type="text" value={email} name="" id="" className="form-control" readOnly />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Backup Email Address</label>
                                        <label htmlFor="">
                                            <input type="text" value={backupEmail} name="" id="" className="form-control" onChange={(e) => setBackupEmail(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Mobile No</label>
                                        <label htmlFor="">
                                            <input type="text" value={mobileNo} name="" id="" className="form-control" onChange={(e) => setMobileNo(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Landline No.</label>
                                        <label htmlFor="">
                                            <input type="text" value={landlineNo} name="" id="" className="form-control" onChange={(e) => setLandlineNo(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Skype ID - Optional</label>
                                        <label htmlFor="">
                                            <input type="text" value={skypeId} name="" id="" className="form-control" onChange={(e) => setSkypeId(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">WhatsApp - Optional</label>
                                        <label htmlFor="">
                                            <input type="text" value={whatsApp} name="" id="" className="form-control" onChange={(e) => setWhatsapp(e.target.value)} />
                                        </label>
                                    </div>
                                </div>

                                <div className="dk-userSbtitle">Reset Password</div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Password</label>
                                        <label htmlFor="" className="dk-userPassword">

                                            <input type={showPassword ? "text" : "password"} className="form-control" name="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="*****" />

                                            <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>

                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Confirm Password</label>
                                        <label htmlFor="" className="dk-userPassword">
                                            <input type={showConfirmPassword ? "text" : "password"} className="form-control" name="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} placeholder="*****" />

                                            <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowConfirmPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>

                                        </label>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <SubmitButton txt="Save Profile" loading={loading} success={success} onClick={submitUserDetails} className="dk-userSaveprofileBtn" position="justify-content-center" />
                    {/* <button className="dk-userSaveprofileBtn">Save Profile</button> */}
                </div>
            </div>
        </>
    )
}
export default UpdateUserDetails;