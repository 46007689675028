import React from 'react';
import { Link, Route } from "react-router-dom";
import '../elements/submenu/style.scss';


const SamplePage = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default SamplePage;
