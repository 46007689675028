import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import ProgressBarReducer from '../slice/utils/loader/linearSlice';
import alertMessagesReducer from '../slice/utils/message/messageSlice';
import authReducer from '../slice/auth/authSlice';
import userListReducer from '../slice/user/userSlice';
import tokenReducer from '../slice/auth/tokenSlice';
import companyReducer from '../slice/companies/companySlice';
import clientUsersReducer from '../slice/clientUsers/';
import tempClientReducer from '../slice/addClientTemp';
import backendValueReducer from '../slice/backendValues';
import loginsReducer from '../slice/logins';
import emailsReducer from '../slice/emails';
import softwareReducer from '../slice/software';

const reducers = combineReducers({
    progressBar: ProgressBarReducer,
    alertMessage: alertMessagesReducer,
    auth: authReducer,
    userList: userListReducer,
    token: tokenReducer,
    companies: companyReducer,
    clientUsers: clientUsersReducer,
    tempClient: tempClientReducer,
    backendValues: backendValueReducer,
    logins: loginsReducer,
    emails: emailsReducer,
    software: softwareReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});