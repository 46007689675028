import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './style.scss';

const DeleteModal = ({ id, handleClose, deleteItem, loading, success }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true)
  }, [id])

  return (
    <>
      <Modal className="dk-deleteModalMain" show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="dk-deleteModal-body">
            <p>Are you sure wants to Delete these records ?</p>

            <div className="d-flex justify-content-end">
              <button onClick={handleClose}>No</button>
              <button loading={loading} success={success} onClick={deleteItem} className="dk-yesBtn">Yes</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteModal;
