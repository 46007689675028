import React, { useState, useEffect } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import Tooltip from "../../ui/tooltip";
const defaultImg = "erp/admin/users/default_user.png";
const UserConstants = require('../../../constants')

const UserList = ({ list, onClick }) => {
    const [userList, setUserList] = useState([]);
    useEffect(() => {

        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.caseAdmin._id)) {
                map.set(item.original.caseAdmin._id, true);
                result.push({
                    id: item.original.caseAdmin._id,
                    name: item.original.caseAdmin.firstName + ' ' + item.original.caseAdmin.lastName,
                    userImg: item.original.caseAdmin.userImg
                });
            }
        }
        setUserList(result)
    }, [list])

    return (
        <React.Fragment>
            {userList.map((list, index) => (
                <Dropdown.Item href="#">
                    <img src={UserConstants.IMG_CLOUD_BASE_URL.Url + (list.userImg != '' ? list.userImg : defaultImg)} className="img-fluid img-circle" alt={list.userImg} title={list.name} id={list.id} onClick={onClick} />
                </Dropdown.Item>
            ))}
        </React.Fragment>
    )
}
export default UserList;