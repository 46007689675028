import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const softwarelist = createAsyncThunk(
    'software/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software/list',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateSoftware = createAsyncThunk(
    'software/update',
    async ({ rowId, division, toolName, department, category, functionality, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software/update/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        division, toolName, department, category, functionality, status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const deleteSoftware = createAsyncThunk(
    'software/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const addSoftware = createAsyncThunk(
    'software/add',
    async ({ department, division, toolName, category, functionality, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        department, division, toolName, category, functionality, status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const detailsById = createAsyncThunk(
    'software/details',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software/details/' + rowId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateStatus = createAsyncThunk(
    'software/changeStatus',
    async ({ rowId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software/updateStatus/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateDepartment = createAsyncThunk(
    'software/changeDepartment',
    async ({ rowId, department }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/software/department/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        department
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const softwareSlice = createSlice({
    name: 'toolsSoftware',
    initialState: {
        allSoftwareList: [],
        softwareDetails: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        isInsert: false,
        errorMessage: '',
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.msg = '';
            return state;
        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
        }
    },
    extraReducers: {
        [softwarelist.pending]: (state) => {
            state.isLoading = true;
        },
        [softwarelist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.allSoftwareList = payload.details;
        },
        [softwarelist.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
        [updateSoftware.pending]: (state) => {
        },
        [updateSoftware.fulfilled]: (state, { payload }) => {
            state.isUpdate = true;
            let mainArray = state.allSoftwareList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.details._id) {
                    return (payload.details)
                } else {
                    return r
                }
            })
            state.allSoftwareList = finalResult
            state.msg = payload.message.msgBody

        },
        [updateSoftware.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [deleteSoftware.pending]: (state) => {
        },
        [deleteSoftware.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.allSoftwareList = state.allSoftwareList.filter((data) => data._id !== action.meta.arg.id)
            state.msg = 'Delete Successfully';
        },
        [deleteSoftware.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [addSoftware.pending]: (state) => {
        },
        [addSoftware.fulfilled]: (state, { payload }) => {
            state.allSoftwareList.unshift(payload.details);
            state.isInsert = true;
            state.msg = payload.message.msgBody
        },
        [addSoftware.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [detailsById.pending]: (state) => {
        },
        [detailsById.fulfilled]: (state, { payload }) => {
            state.softwareDetails = payload.details;
        },
        [detailsById.rejected]: (state, { payload }) => {
        },
        [updateStatus.pending]: (state) => {
        },
        [updateStatus.fulfilled]: (state, action) => {
            let mainArray = state.allSoftwareList;
            mainArray.map((r) => {
                if (r._id === action.meta.arg.rowId) {
                    return (r.status = action.meta.arg.status)
                } else {
                    return r
                }
            })
            state.isUpdate = true;
            state.msg = ""
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isUpdate = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateDepartment.pending]: (state) => {
            state.isUpdate = false;
        },
        [updateDepartment.fulfilled]: (state, action) => {
            let mainArray = state.allSoftwareList;
            mainArray.map((r) => {
                if (r._id === action.meta.arg.rowId) {
                    return (r.department = action.meta.arg.department)
                } else {
                    return r
                }
            })
            state.isUpdate = true;
            state.msg = ""
        },
        [updateDepartment.rejected]: (state, { payload }) => {
            state.isUpdate = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
    }
})
export const { clearState, clearMessage } = softwareSlice.actions
export default softwareSlice.reducer;