import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const emailslist = createAsyncThunk(
    'emails/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/emails/list',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateEmails = createAsyncThunk(
    'emails/update',
    async ({ rowId, email, url, category, password }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/emails/update/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        rowId, email, url, category, password
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const deleteEmails = createAsyncThunk(
    'emails/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/emails/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const addEmails = createAsyncThunk(
    'emails/add',
    async ({ email, url, category, password }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/emails',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        email, url, category, password
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const detailsById = createAsyncThunk(
    'emails/details',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/emails/details/' + rowId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const emailsSlice = createSlice({
    name: 'toolsEmails',
    initialState: {
        emails: [],
        emailDetails: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        isInsert: false,
        errorMessage: '',
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.msg = '';
            return state;

        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
        }
    },
    extraReducers: {
        [emailslist.pending]: (state) => {
            state.isLoading = true;
        },
        [emailslist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.emails = payload.emails;
        },
        [emailslist.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
        [updateEmails.pending]: (state) => {
        },
        [updateEmails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.emails;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.emails = finalResult
            state.msg = payload.message.msgBody

        },
        [updateEmails.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [deleteEmails.pending]: (state) => {
        },
        [deleteEmails.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.emails = state.emails.filter((data) => data._id !== action.meta.arg.id)
            state.msg = 'Delete Successfully';
        },
        [deleteEmails.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [addEmails.pending]: (state) => {
        },
        [addEmails.fulfilled]: (state, { payload }) => {
            state.emails.push(payload.details);
            state.isInsert = true;
            state.msg = payload.message.msgBody
        },
        [addEmails.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [detailsById.pending]: (state) => {
        },
        [detailsById.fulfilled]: (state, { payload }) => {
            state.emailDetails = payload.details;
        },
        [detailsById.rejected]: (state, { payload }) => {
        }
    }
})
export const { clearState, clearMessage } = emailsSlice.actions
export default emailsSlice.reducer;