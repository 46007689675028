import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
const LoginRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate } = useSelector(state => state.auth)
    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                return <Redirect to={{ pathname: '/dashboard/samplepage1', state: { from: props.location } }} />
            return <Component {...props} />
        }} />
    )
};
export default LoginRoute;