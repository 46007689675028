import React from 'react';
import { Route, Link } from "react-router-dom";
import './faq.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import AddCategoryModal from './AddCategoryModal';

const FaqQuestion = () => {
    return (
        <React.Fragment>
            <div className="dk-faqMain">
                <div className="dk-faqHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-faqleft-btn">
                            <Link to="/settings/faq"><i class="fas fa-arrow-left"></i> Back</Link>
                            <AddCategoryModal />
                        </div>
                        <h3 className="title">FAQ - QUESTION SETTINGS</h3>
                        <div className="dk-faqRight">
                            <Route>
                                <Link to="#"><i class="fas fa-arrows-alt"></i> Reorder</Link>
                            </Route>
                        </div>
                    </div>
                </div>
                <div className="dk-faqTableMain dk-faqQuestion">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SNo.</th>
                                    <th>Category</th>
                                    <th>Questions</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">1</td>
                                    <td>superadmin1</td>
                                    <td>Lorem Ipsum is simply dummy text of the printing 1</td>
                                    <td className="text-center">
                                        <div className="dk-faqStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="dk-faqAction">
                                            <button><i class="far fa-edit"></i></button>
                                            <button><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FaqQuestion;
