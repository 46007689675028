import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch } from "react-router-dom";

import LoginRoute from "./components/hocs/LoginRoute";
import PrivateRoute from './components/hocs/PrivateRoute';

import Dashboard from './components/pages/dashboard';
import Mailbox from './components/pages/mailbox';
import Alerts from './components/pages/alerts';
import Accounts from './components/pages/accounts';
import Package from './components/pages/package';
import Billings from './components/pages/billings';
import Support from './components/pages/support';
import SitePages from './components/pages/sitepages';
import SamplePage from './components/pages/SamplePage';
import BkValues from './components/pages/tools/BkValues';
import Logins from './components/pages/tools/Logins';
import Emails from './components/pages/tools/Emails';
import Software from './components/pages/tools/Software';
import AdminLogins from './components/pages/login';
import SettingsUsers from './components/pages/settings/Users';
import LiveApps from './components/pages/apps/LiveApps';
import CreateApps from './components/pages/apps/CreateApps';
import AccountClients from './components/pages/accounts/clients';
import AccountUsers from './components/pages/accounts/users';
import StepOne from './components/pages/accounts/addclient/StepOne';
import StepTwo from './components/pages/accounts/addclient/StepTwo';
import StepThree from './components/pages/accounts/addclient/StepThree';
import SavedApps from './components/pages/apps/SavedApps';
import StandardApps from './components/pages/apps/StandardApps';
import CustomApps from './components/pages/apps/CustomApps';
import Faq from './components/pages/settings/faq/Faq';
import FaqQuestion from './components/pages/settings/faq/FaqQuestion';
import Restaurants from './components/pages/apps/Restaurants';
import DanceFitness from './components/pages/apps/DanceFitness';
import RetailBusiness from './components/pages/apps/RetailBusiness';
import SelfEmployed from './components/pages/apps/SelfEmployed';
import CustomisedApps from './components/pages/apps/CustomisedApps';

function App() {
  return (
    <React.Fragment>
      <Switch>
        <LoginRoute exact path="/" component={AdminLogins} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/mailbox" component={Mailbox} />
        <PrivateRoute path="/alerts" component={Alerts} />
        <PrivateRoute path="/apps/live-apps" component={LiveApps} />
        <PrivateRoute path="/apps/create-apps" component={CreateApps} />
        <PrivateRoute path="/apps/custom-apps" component={CustomApps} />
        <PrivateRoute path="/apps/restaurants" component={Restaurants} />
        <PrivateRoute path="/apps/dance-fitness" component={DanceFitness} />
        <PrivateRoute path="/apps/retail-business" component={RetailBusiness} />
        <PrivateRoute path="/apps/self-employed" component={SelfEmployed} />
        <PrivateRoute path="/apps/saved-apps" component={SavedApps} />
        <PrivateRoute path="/apps/standard-apps" component={StandardApps} />
        <PrivateRoute path="/apps/customised-apps" component={CustomisedApps} />
        <PrivateRoute path="/package" component={Package} />
        <PrivateRoute path="/billings" component={Billings} />
        <PrivateRoute path="/support" component={Support} />
        <PrivateRoute path="/sitepages" component={SitePages} />
        <PrivateRoute path="/settings/users" component={SettingsUsers} />
        <PrivateRoute path="/settings/faq/faq-question" component={FaqQuestion} />
        <PrivateRoute path="/settings/faq" component={Faq} />
        <PrivateRoute path="/tools/logins" component={Logins} />
        <PrivateRoute path="/tools/bkvalues" component={BkValues} />
        <PrivateRoute path="/tools/emails" component={Emails} />
        <PrivateRoute path="/tools/software" component={Software} />
        <PrivateRoute path="/accounts/companies" component={AccountClients} />
        <PrivateRoute path="/accounts/users" component={AccountUsers} />
        <PrivateRoute path="/accounts/add-client/step-1" component={StepOne} />
        <PrivateRoute path="/accounts/add-client/step-2/:id" component={StepTwo} />
        <PrivateRoute path="/accounts/add-client/step-3/:id" component={StepThree} />
        <PrivateRoute path="/accounts" component={Accounts} />


        <PrivateRoute path="/dashboard/samplepage1" component={SamplePage} />
        <PrivateRoute path="/dashboard/samplepage2" component={SamplePage} />
        <PrivateRoute path="/mailbox/samplepage1" component={SamplePage} />
        <PrivateRoute path="/mailbox/samplepage2" component={SamplePage} />
        <PrivateRoute path="/alerts/samplepage1" component={SamplePage} />
        <PrivateRoute path="/alerts/samplepage2" component={SamplePage} />
        <PrivateRoute path="/accounts/samplepage1" component={SamplePage} />
        <PrivateRoute path="/accounts/samplepage2" component={SamplePage} />
        <PrivateRoute path="/package/samplepage1" component={SamplePage} />
        <PrivateRoute path="/package/samplepage2" component={SamplePage} />
        <PrivateRoute path="/billings/samplepage1" component={SamplePage} />
        <PrivateRoute path="/billings/samplepage2" component={SamplePage} />
        <PrivateRoute path="/support/samplepage1" component={SamplePage} />
        <PrivateRoute path="/support/samplepage2" component={SamplePage} />
        <PrivateRoute path="/sitepages/samplepage1" component={SamplePage} />
        <PrivateRoute path="/sitepages/samplepage2" component={SamplePage} />
        <PrivateRoute path="/settings" component={SamplePage} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
