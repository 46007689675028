import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addTempClient = createAsyncThunk(
    'tempClient/add',
    async ({ email, domain }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/clientUsersTemp',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        email,
                        domain,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateTempClient = createAsyncThunk(
    'tempClient/update',
    async ({ id, industry, businessType, employees, businessName, city, country }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/clientUsersTemp/businessDetails/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        industry, businessType, employees, businessName, city, country
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateTempAdmin = createAsyncThunk(
    'tempAdmin/update',
    async ({ id, adminName, adminEmail, password, confirmPassword, hereAbout }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const passcode = password;
            const response = await fetch('/api/clientUsersTemp/adminDetails/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        adminName, adminEmail, password, confirmPassword, hereAbout, passcode
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


const tempClientSlice = createSlice({
    name: 'addTempClient',
    initialState: {
        tempClientInfo: {},
        clientData: [],
        isSuccess: false,
        isError: false,
        isLoading: false,
        isInsert: false,
        isUpdate: false,
        msg: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.msg = '';
            state.isInsert = false
            state.isUpdate = false
            return state;
        }
    },
    extraReducers: {
        [addTempClient.pending]: (state) => {
            state.isLoading = true;
        },
        [addTempClient.fulfilled]: (state, { payload }) => {
            state.tempClientInfo = payload.tempUserClient;
            state.isInsert = true;
            state.isLoading = false;
        },
        [addTempClient.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [updateTempClient.pending]: (state) => {
            state.isLoading = true;
        },
        [updateTempClient.fulfilled]: (state, { payload }) => {
            state.isUpdate = true;
            state.isLoading = false;
        },
        [updateTempClient.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [updateTempAdmin.pending]: (state) => {
            state.isLoading = true;
        },
        [updateTempAdmin.fulfilled]: (state, { payload }) => {
            state.tempClientInfo = payload.data;
            state.isSuccess = true;
            state.isLoading = false;
            state.msg = payload.message.msgBody;
            state.isError = false;
        },
        [updateTempAdmin.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },

    }
});
export const { clearState } = tempClientSlice.actions;
export default tempClientSlice.reducer;