import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';
import Logo from "../../../assets/images/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../slice/auth/authSlice";
import Tooltip from "../../ui/tooltip";
const ClientConstants = require('../../../constants')

const Header = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);

    const logout = async () => {
        dispatch(logoutUser({}))
    }

    return (
        <React.Fragment>
            <header>
                <div className="dk-headerMain">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-logo">
                            <Route>
                                <Link to="/"><img src={Logo} className="img-fluid" alt="" /></Link>
                            </Route>
                        </div>
                        <div className="dk-headTitle">ADMIN - DK GLOBAL ERP</div>
                        <div className="dk-headRight">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <span>
                                        <Tooltip type="img" title={userInfo.name} value={ClientConstants.IMG_CLOUD_BASE_URL.Url + userInfo.userImg} className="img-fluid img-circle" />
                                    </span>
                                    <span className="dropdownIcon"><ArrowDropDownIcon /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={logout}><ExitToAppIcon /> Sign Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

export default Header;