import React, { useState, useEffect } from 'react'
import "./selectinstance.css";
import { Select } from 'semantic-ui-react'

const SelectInstance = ({ list, placeholder, name, onChange, selectedOptions }) => {
    const [ddlList, setDdlList] = useState([]);
    console.log(list)
    useEffect(() => {
        var result = [];
        var map = new Map();
        if (list != '') {
            for (var item of list) {
                if (!map.has(item.name)) {
                    map.set(item.name, true);
                    result.push({
                        key: item._id,
                        value: item.name,
                        text: item.name,
                        style: {
                            color: item.textColor,
                            backgroundColor: item.bgColor
                        }
                    })
                }
            }
            result.unshift({
                key: '0',
                value: '',
                text: placeholder,
                style: {
                    display: 'none'
                }
            })
        }
        setDdlList(result)
    }, [list])

    return (
        <React.Fragment>
            <Select placeholder={placeholder} options={ddlList} className={`ui fluid selection dropdown`} name={name} onChange={onChange} value={selectedOptions} />
        </React.Fragment>
    )
}
export default SelectInstance;