import React, { useState, useEffect } from 'react'
import "./selectinstance.css";
import { Select } from 'semantic-ui-react'

const SelectInstance = ({ list, placeholder, name, onChange, selectedOptions }) => {
    const [ddlList, setDdlList] = useState([]);
    console.log(list);
    // return false;
    useEffect(() => {
        var result = [];
        var map = new Map();
        for (var item of list) {
            if (!map.has(item.original.status)) {
                map.set(item.original.status, true);
                result.push({
                    key: item.original._id,
                    value: item.original.status,
                    text: item.original.status,
                    style: {
                        color: item.original.textColor,
                        backgroundColor: item.original.bgColor
                    }
                })
            }
        }
        result.unshift({
            key: '0',
            value: '',
            text: placeholder,
            style: {
                display: 'none'
            }
        })
        setDdlList(result)
    }, [list])

    return (
        <React.Fragment>
            <Select placeholder={placeholder} clearable options={ddlList} className={`dk-overdues-task-slect`} name={name} onChange={onChange} value={selectedOptions} />
        </React.Fragment>
    )
}
export default SelectInstance;