import React, { useState, useMemo, useEffect } from "react";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AddModal from "./modals/AddNewModal";
import UpdateDetails from "./UpdateUserDetails";
import { userslist, usersByRole, clearState, userDelete, updateStatus, updateRole, usersById } from "../../../slice/user/userSlice";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import NoData from "../../utils/tableNoData";
import TableLoader from "../../utils/tableLoader/taskTable";
import { showSuccess, showError, clearMessage } from '../../../slice/utils/message/messageSlice';
import Tooltip from "../../ui/tooltip";
import DeleteModal from "../../ui/delete/DeleteModal";
import Authentication from "../../helpers/authenticate";
import Dropdown from 'react-bootstrap/Dropdown';
const UserConstants = require('../../../constants')

function Table({ loading, columns, data }) {
    const tableInstance = useTable({
        columns: columns,
        data: data
    }, useGlobalFilter, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = tableInstance
    const { globalFilter } = state

    return (
        <React.Fragment>
            <table className="table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="6" txt="No user list found . . ." /> : null}
                {
                    (loading) ? <TableLoader colspan="12" /> :
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                }
            </table>
        </React.Fragment>
    )
}

const Users = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [userRole, setUserRole] = useState([]);
    const { users, usersRole, isLoading, isDelete, msg, isError, isInsert, isUpdate, userDetails } = useSelector(state => state.userList);
    const [rowId, setRowId] = useState('0')
    const [allUserCount, setAllUserCount] = useState('0');
    const [superAdminCount, setSuperAdminCount] = useState('0');
    const [adminCount, setAdminCount] = useState('0');
    const [usersCount, setUsersCount] = useState('0');
    const [showDelete, setShowDelete] = useState(false)
    const [show, setShow] = useState(false);
    const [statusVal, setStatusVal] = useState('Active');
    const [showProfile, setShowProfile] = useState(false);

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            setData(users)
            setUserRole(usersRole)
            setAllUserCount(users.length)
            setSuperAdminCount(users.filter(items => items.role == 'Super Admin').length)
            setAdminCount(users.filter(items => items.role == 'Admin').length)
            setUsersCount(users.filter(items => items.role == 'User').length)
            setShowDelete(false)
        }
        // if (isError) {
        //     dispatch(showError({ msg: msg }))
        //     dispatch(clearState())
        //     dispatch(clearMessage())
        // }
    }, [isDelete])
    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            setData(users)
        }
    })

    useEffect(() => {
        if (!isLoading && users && users.length > 0) {
            setData(users)
            setUserRole(usersRole)
            setAllUserCount(users.length)
            setSuperAdminCount(users.filter(items => items.role == 'Super Admin').length)
            setAdminCount(users.filter(items => items.role == 'Admin').length)
            setUsersCount(users.filter(items => items.role == 'User').length)
        }
    }, [isLoading])


    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(userslist({}))
                setAllUserCount(users.length)
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])

    const columns = useMemo(() => [
        {
            Header: 'Users',
            title: 'Users',
            Cell: ({ cell }) => (
                <div className="dk-userImg">
                    <Tooltip type="img" title={cell.row.original.firstName + " " + cell.row.original.lastName} value={UserConstants.IMG_CLOUD_BASE_URL.Url + cell.row.original.userImg} className="img-circle" />
                </div>
            )
        },
        {
            Header: 'Name of User',
            title: 'Name of User',
            Cell: ({ cell }) => (<>
                {cell.row.original.firstName + " " + cell.row.original.lastName}
            </>
            )
        },
        {
            Header: 'User Email',
            title: 'User Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.email}
            </>
            )
        },
        {
            Header: 'System ID',
            title: 'System ID',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemId}
            </>
            )
        },
        {
            Header: 'System Email',
            title: 'System Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemEmail}
            </>
            )
        },
        {
            Header: 'Role',
            title: 'Role',
            Cell: ({ cell }) => (<>
                {cell.row.original.role}
            </>
            )
        },
        {
            Header: 'Status',
            Cell: ({ cell }) => {
                return (
                    <div className={(cell.row.original.status === "Inactive") ? 'dk-userStatusBtn dk-userActiveBtn dk-inactiveBtn' : 'dk-userStatusBtn dk-userActiveBtn'}>
                        <span>{cell.row.original.status}</span>
                    </div>
                )
            }
        }
    ], [])

    const editColumns = useMemo(() => [
        {
            Header: 'Users',
            title: 'Users',
            Cell: ({ cell }) => (
                <div className="dk-userImg">
                    <Tooltip type="img" title={cell.row.original.firstName + " " + cell.row.original.lastName} value={UserConstants.IMG_CLOUD_BASE_URL.Url + cell.row.original.userImg} className="img-circle" />
                </div>
            )
        },
        {
            Header: 'Name of User',
            title: 'Name of User',
            Cell: ({ cell }) => (<>
                {cell.row.original.firstName + " " + cell.row.original.lastName}
            </>
            )
        },
        {
            Header: 'User Email',
            title: 'User Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.email}
            </>
            )
        },
        {
            Header: 'System ID',
            title: 'System ID',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemId}
            </>
            )
        },
        {
            Header: 'System Email',
            title: 'System Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemEmail}
            </>
            )
        },
        {
            Header: 'Role',
            title: 'Role',
            Cell: ({ cell }) => (<>
                <Dropdown className="dk-roleDropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span>{cell.row.original.role}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item id={cell.row.original._id} onClick={changeRole} data-key='Super Admin' >Super Admin</Dropdown.Item>
                        <Dropdown.Item id={cell.row.original._id} onClick={changeRole} data-key='Admin' >Admin</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
            )
        },
        {
            Header: 'Status',
            Cell: ({ cell }) => {
                return (
                    <div className={(cell.row.original.status === 'Inactive') ? 'dk-userStatusBtn dk-inactiveBtn' : 'dk-userStatusBtn'}>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <span>{cell.row.original.status}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item id={cell.row.original._id} onClick={changeStatus} data-key='Active' >Active</Dropdown.Item>
                                <Dropdown.Item id={cell.row.original._id} onClick={changeStatus} data-key='Inactive' >Inactive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        },
        {
            Header: 'Edit Profile',
            Cell: ({ cell }) => {
                return (
                    <div className="dk-userEditBtn">
                        <button onClick={() => showEdit(cell.row.original._id)}>Edit</button>
                    </div>
                )
            }
        }
    ], [])
    const showEdit = async (userId) => {
        await dispatch(usersById({ userId }))
        setShowProfile(true);
    }

    const changeRole = (e) => {
        dispatch(updateRole({ rowId: e.target.id, role: e.target.dataset.key }))
    }
    const changeStatus = (e) => {
        dispatch(updateStatus({ rowId: e.target.id, status: e.target.dataset.key }))
    }
    const deleteUser = (id) => {
        setRowId(id)
        setShowDelete(true)
    }
    const handleClose = () => {
        setRowId('0')
        setShow(false)
        setShowDelete(false)
    }
    const deleteItem = async () => {
        dispatch(userDelete({ rowId }))
    }
    const deleteColumns = useMemo(() => [
        {
            Header: 'Users',
            title: 'Users',
            Cell: ({ cell }) => (
                <div className="dk-userImg">
                    <Tooltip type="img" title={cell.row.original.firstName + " " + cell.row.original.lastName} value={UserConstants.IMG_CLOUD_BASE_URL.Url + cell.row.original.userImg} className="img-circle" />
                </div>
            )
        },
        {
            Header: 'Name of User',
            title: 'Name of User',
            Cell: ({ cell }) => (<>
                {cell.row.original.firstName + " " + cell.row.original.lastName}
            </>
            )
        },
        {
            Header: 'User Email',
            title: 'User Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.email}
            </>
            )
        },
        {
            Header: 'System ID',
            title: 'System ID',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemId}
            </>
            )
        },
        {
            Header: 'System Email',
            title: 'System Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemEmail}
            </>
            )
        },
        {
            Header: 'Role',
            title: 'Role',
            Cell: ({ cell }) => (<>
                {cell.row.original.role}
            </>
            )
        },
        {
            Header: 'Status',
            Cell: ({ cell }) => {
                return (
                    <div className={cell.row.original.status === 'Inactive' ? 'dk-userStatusBtn dk-userActiveBtn dk-inactiveBtn' : 'dk-userStatusBtn dk-userActiveBtn'} >
                        <span>{cell.row.original.status}</span>
                    </div>
                )
            }
        },
        {
            Header: 'Delete User',
            Cell: ({ cell }) => {
                return (
                    <div className="dk-userDeleteBtn">
                        <button onClick={() => deleteUser(cell.row.original._id)} >Delete</button>
                    </div>
                )
            }
        }
    ], [])


    const getUser = async (key) => {
        switch (key) {
            case 'All':
                await dispatch(userslist({}))
                setAllUserCount(users.length)
                break;
            case 'Super Admin':
                await dispatch(usersByRole({ role: 'Super Admin' }))
                setSuperAdminCount(users.filter(items => items.role == 'Super Admin').length)
                break;
            case 'Admin':
                await dispatch(usersByRole({ role: 'Admin' }))
                setAdminCount(users.filter(items => items.role == 'Admin').length)
                break;

            case 'editTab':
                setShowProfile(false)
                break;

            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <Authentication />
            {
                (showDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            <div className="dk-settingsUserMain">
                <div className="dk-settingUserTabs">
                    <Tabs defaultActiveKey="All" onSelect={getUser} id="uncontrolled-tab-example">
                        <Tab eventKey="All" title={"All - " + allUserCount}>
                            <div className="dk-userTableMain">
                                <Table
                                    data={data}
                                    columns={columns}
                                    loading={isLoading}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Super Admin" title={"Super Admin - " + superAdminCount}>
                            <div className="dk-userTableMain">
                                <Table
                                    data={userRole}
                                    columns={columns}
                                    loading={isLoading}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Admin" title={"Admin - " + adminCount}>
                            <div className="dk-userTableMain">
                                <Table
                                    data={userRole}
                                    columns={columns}
                                    loading={isLoading}
                                />
                            </div>
                        </Tab>

                        <Tab eventKey="tabs5" className="dk-userSettingAddnew-container" title="Add New">
                            <div className="dk-userSetting-addNew">
                                <AddModal />
                            </div>
                        </Tab>
                        <Tab eventKey="editTab" title="Edit Users">
                            {
                                (showProfile) ?
                                    <UpdateDetails />
                                    : <div className="dk-userTableMain">
                                        <Table
                                            data={data}
                                            columns={editColumns}
                                            loading={isLoading}
                                        />
                                    </div>
                            }
                        </Tab>
                        <Tab eventKey="tabs8" title="Delete User">
                            <div className="dk-userTableMain">
                                <Table
                                    data={data}
                                    columns={deleteColumns}
                                    loading={isLoading}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Users;