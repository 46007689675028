import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';

const AppsSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-submMenuMain">
                <div className="dk-subMenu">
                    <Route>
                        <ul>
                            <li><Link to="/apps/live-apps" className={`${pathname.match('/apps/live-apps') ? 'sub-active' : ''}`}>Live Apps</Link></li>
                            <li><Link to="/apps/saved-apps" className={`${pathname.match('/apps/saved-apps') ? 'sub-active' : ''}`}>Saved Apps</Link></li>
                            <li><Link to="/apps/standard-apps" className={`${pathname.match('/apps/standard-apps') ? 'sub-active' : ''}`}>Standard Apps</Link></li>
                            <li><Link to="/apps/custom-apps" className={`${pathname.match('/apps/custom-apps') ? 'sub-active' : ''}`}>Custom Apps</Link></li>
                            <li><Link to="/apps/create-apps" className={`${pathname.match('/apps/create-apps') ? 'sub-active' : ''}`}>Create App</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AppsSubmenu;
