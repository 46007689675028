import React from 'react';
import Header from "../elements/header";
import Navbar from "../elements/navbar";
import DashboardSubmenu from "../elements/submenu/DashboardSubmenu";
import MailboxSubmenu from "../elements/submenu/MailboxSubmenu";
import AlertsSubmenu from "../elements/submenu/AlertsSubmenu";
import AccountsSubmenu from "../elements/submenu/AccountsSubmenu";
import AppsSubmenu from "../elements/submenu/AppsSubmenu";
import PackageSubmenu from "../elements/submenu/PackageSubmenu";
import BillingSubmenu from "../elements/submenu/BillingSubmenu";
import SupportSubmenu from "../elements/submenu/SupportSubmenu";
import SettingsSubmenu from "../elements/submenu/SettingsSubmenu";
import ToolsSubmenu from "../elements/submenu/ToolsSubmenu";
import SitepagesSubmenu from "../elements/submenu/SitepagesSubmenu";

const Layout = ({ children }) => {
    let pathname = window.location.pathname;
    const renderSubmenu = () => {
        switch (pathname.split("/")[1]) {
            case 'dashboard':
                return <DashboardSubmenu />
                break;

            case 'mailbox':
                return <MailboxSubmenu />
                break;

            case 'alerts':
                return <AlertsSubmenu />
                break;

            case 'accounts':
                return <AccountsSubmenu />
                break;

            case 'apps':
                return <AppsSubmenu />
                break;

            case 'package':
                return <PackageSubmenu />
                break;

            case 'billings':
                return <BillingSubmenu />
                break;

            case 'support':
                return <SupportSubmenu />
                break;

            case 'sitepages':
                return <SitepagesSubmenu />
                break;

            case 'tools':
                return <ToolsSubmenu />
                break;

            case 'settings':
                return <SettingsSubmenu />
                break;
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Navbar />
            {renderSubmenu()}
            {children}
        </React.Fragment>
    )
}
export default Layout;