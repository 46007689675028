import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const userslist = createAsyncThunk(
    'users/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/list/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const usersByRole = createAsyncThunk(
    'users/listRole',
    async ({ role }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/list/' + role,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const userDelete = createAsyncThunk(
    'user/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const registerUser = createAsyncThunk(
    'user/add',
    async ({ firstName, lastName, email, role, status, systemId, systemEmail }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/register',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        firstName, lastName, email, role, status, systemId, systemEmail
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const updateStatus = createAsyncThunk(
    'users/changeStatus',
    async ({ rowId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/updateStatus/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateRole = createAsyncThunk(
    'users/changeRole',
    async ({ rowId, role }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/updateRole/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        role
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const usersById = createAsyncThunk(
    'users/listById',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateDetails = createAsyncThunk(
    'users/updateUserDetails',
    async ({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, userImg }, thunkAPI) => {
        try {
            let lastName;
            var lsName = lName.split(" ");
            if (lsName[1] === "undefined") {
                lastName = lsName[0];

            } else {
                lastName = lName
            }
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/user/' + userId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        file, firstName, lastName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, userImg
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        usersRole: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        userDetails: {}
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
        }

    },
    extraReducers: {

        [userslist.pending]: (state) => {
            state.isLoading = true;
        },
        [userslist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = payload.userList;
        },
        [userslist.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [usersByRole.pending]: (state) => {
            state.isLoading = true;
        },
        [usersByRole.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.usersRole = payload.userList;
        },
        [usersByRole.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [userDelete.pending]: (state) => {
        },
        [userDelete.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.users = state.users.filter((user) => user._id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [userDelete.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [registerUser.pending]: (state) => {
            state.isLoading = true;
        },
        [registerUser.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.users.push(payload.data)
            state.msg = payload.message.msgBody
        },
        [registerUser.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateStatus.pending]: (state) => {
            state.isLoading = true;
        },
        [updateStatus.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.users;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.users = finalResult
            state.msg = payload.message.msgBody
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [updateRole.pending]: (state) => {
            state.isLoading = true;
        },
        [updateRole.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.users;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.users = finalResult
            state.msg = payload.message.msgBody
        },
        [updateRole.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [usersById.pending]: (state) => {
            state.isLoading = true;
        },
        [usersById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userDetails = payload.userDetails;
        },
        [usersById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [updateDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.users;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.users = finalResult;
            state.userDetails = payload.data;
            state.msg = payload.message.msgBody;
        },
        [updateDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
    }
})
export const { clearState } = usersSlice.actions
export default usersSlice.reducer;