import React, { useState, useEffect } from 'react';
import { Link, Route, useParams, Redirect } from "react-router-dom";
import './addclient.scss';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
//import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import BackendValuesDropdown from '../../../ui/backendValuesDropdown';
import { hereAboutList } from "../../../../slice/backendValues";
import { updateTempAdmin, clearState } from "../../../../slice/addClientTemp";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../../slice/utils/loader/linearSlice";
import Authentication from "../../../helpers/authenticate";
import SubmitButton from "../../../ui/submitButton/index";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const StepThree = () => {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { msg, isSuccess, isError } = useSelector(state => state.tempClient)
    const { hereAboutUs } = useSelector(state => state.backendValues)
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hereAbout, setHereAbout] = useState('');
    const [isReset, setIsReset] = useState(false)
    const [nextPage, setNextPage] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const resetIsClear = () => {
        setIsReset(false)
    }
    const hereAboutSelect = (event, data) => {
        const { name, value } = data
        setHereAbout(value)
    }

    useEffect(() => {
        if (isError) {
            // dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(showError({ msg: msg }))
            dispatch(clearLoading())
        }
        if (isSuccess) {
            setAdminName('');
            setAdminEmail('');
            setPassword('');
            setConfirmPassword('');
            setHereAbout('');
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            // dispatch(loadingEnd())
            setNextPage(true)
            dispatch(clearLoading())
        }
    }, [isError, isSuccess])

    useEffect(() => {
        const hereAboutFunction = async () => {
            await dispatch(hereAboutList({}))
        }
        hereAboutFunction();
    }, [])
    const submitData = (e) => {
        e.preventDefault()

        if (!adminName || !adminEmail || !password || !confirmPassword || !hereAbout) {
            dispatch(loadingEnd())
            dispatch(showError({ msg: 'All fields required' }));
        } else {
            dispatch(loadingStart())
            setSuccess(false);
            setLoading(true);
            dispatch(updateTempAdmin({ id: id, adminName: adminName, adminEmail: adminEmail, password: password, confirmPassword: confirmPassword, hereAbout: hereAbout }));
        }
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const ClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    return (
        <React.Fragment>

            <Authentication />

            {nextPage ? <Redirect to={"/accounts/add-client/step-1"} /> : ""}

            <div className="dk-addClientMain">
                <div className="dk-signupMain">
                    <div className="d-signUpFlex">
                        <h3 className="title mb-3">ADMIN PERSON DETAILS</h3>
                        <div className="dk-signupContainer">
                            <form action="">
                                <div className="form-group mb-4">
                                    <label htmlFor="">Admin Full Name</label>
                                    <input type="text" placeholder="Enter Full Name" name="name" className="form-control col-md-12 m-auto" onChange={(e) => setAdminName(e.target.value)} value={adminName} />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="">Admin Email Address</label>
                                    <input type="text" placeholder="Enter Email Address" name="email" className="form-control col-md-12 m-auto" onChange={(e) => setAdminEmail(e.target.value)} value={adminEmail} />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="">Password</label>
                                    <div className="d-flex align-items-center">
                                        <input type={showPassword ? "text" : "password"} className="form-control right-no-radius" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />

                                        <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="">Confirm Password</label>
                                    <div className="d-flex align-items-center">
                                        <input type={showConfirmPassword ? "text" : "password"} className="form-control right-no-radius" name="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                        <span className="dk-eyeICon domainAppendSpan" onClick={ClickShowConfirmPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="">How did you hear about us ?</label>
                                    <label htmlFor="">
                                        <BackendValuesDropdown list={hereAboutUs} placeholder="Select" name="" onChange={hereAboutSelect} reset={isReset} clearReset={resetIsClear} selectedOptions={hereAbout} />

                                    </label>
                                    {/* <label htmlFor="" className="dk-agreeTerms d-flex align-items-center justify-content-center">
                                        <input type="checkbox" name="" id="" />
                                        <span style={{ fontSize: '12px', marginLeft: '5px' }}>I agree with all <Link to="#" style={{ color: '#2d96b6' }}>Terms & Conditions</Link> to create account.</span>
                                    </label> */}
                                </div>
                                <div className="text-center dk-submitBtn mt-1">
                                    <Route>
                                        <div className="d-flex dk-addCliBtnCon">
                                            <SubmitButton txt="Submit" loading={loading} success={success} onClick={submitData} position="justify-content-center" className="dk-addCliBtn" />
                                        </div>
                                    </Route>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StepThree;
