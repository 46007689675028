import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';

const PackageSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-submMenuMain">
                <div className="dk-subMenu">
                    <Route>
                        <ul>
                            <li><Link to="/package/samplepage1" className={`${pathname.match('/samplepage1') ? 'sub-active' : ''}`}>Standard</Link></li>
                            <li><Link to="/package/samplepage2" className={`${pathname.match('/samplepage2') ? 'sub-active' : ''}`}>Premium</Link></li>
                            <li><Link to="/package/samplepage3" className={`${pathname.match('/samplepage3') ? 'sub-active' : ''}`}>Enterprise</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PackageSubmenu;
