import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const clientuserlist = createAsyncThunk(
    'clientUser/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/clientUsers/list/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateStatus = createAsyncThunk(
    'clientUser/changeStatus',
    async ({ rowId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/clientUsers/updateStatus/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const deleteClientUserAccount = createAsyncThunk(
    'clientUser/delete/account',
    async ({ id, }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/clientUsers/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

const clientUserSlice = createSlice({
    name: 'clientUser',
    initialState: {
        userList: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        isInsert: false,
        errorMessage: '',
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.msg = '';
            return state;

        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
        }
    },
    extraReducers: {
        [clientuserlist.pending]: (state) => {
            state.isLoading = true;
        },
        [clientuserlist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.userList = payload.userList;
        },
        [clientuserlist.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
        [updateStatus.pending]: (state) => {
        },
        [updateStatus.fulfilled]: (state, action) => {
            let mainArray = state.userList;
            mainArray.map((r) => {
                if (r._id === action.meta.arg.rowId) {
                    return (r.status = action.meta.arg.status)
                } else {
                    return r
                }
            })
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [deleteClientUserAccount.pending]: (state) => {
        },
        [deleteClientUserAccount.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.userList = state.userList.filter((clientUser) => clientUser._id !== action.meta.arg.id)
            state.msg = 'Delete Successfully';
        },
        [deleteClientUserAccount.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
    }
})

export const { learState, clearMessage } = clientUserSlice.actions
export default clientUserSlice.reducer;