import React from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import SupportImg from "../../../assets/images/support.png";
import ReportImg from "../../../assets/images/report.png";
import ResourcesImg from "../../../assets/images/resources.png";
import AnnouncementsImg from "../../../assets/images/announcements.png";
import LeadsImg from "../../../assets/images/leads.png";
import MarketingImg from "../../../assets/images/marketing.png";
import PurchaseImg from "../../../assets/images/purchase.png";
import SalesImg from "../../../assets/images/sales.png";
import TrainingImg from "../../../assets/images/training.png";
import HrImg from "../../../assets/images/hr.png";
import FinanceImg from "../../../assets/images/finance.png";
import AccountingImg from "../../../assets/images/accounting.png";
import TaskImg from "../../../assets/images/task.png";
import ProjectsImg from "../../../assets/images/projects.png";
import MyFiles from "../../../assets/images/myfiles.png";

const StandardApps = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-appsStandardMain">
                <div className="dk-container-apps">
                    <div className="dk-appsSideMenu">
                        <ul>
                            <li><Link to="/apps/standard-apps" className={`${pathname.match('/apps/standard-apps') ? 'active' : ''}`}>Standard Apps</Link></li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_9">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={TrainingImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Training</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_2">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={ReportImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Report</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_1">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={SupportImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Support</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_16">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={TaskImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Task</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_4">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={AnnouncementsImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Announcements</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_15">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={ProjectsImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Projects</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_13">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={LeadsImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">New Leads</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_6">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={MarketingImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Marketing</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_7">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={PurchaseImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Purchase</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_8">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={SalesImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Sales</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_14">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={MyFiles} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">My Files</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_3">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={ResourcesImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Resources</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_10">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={HrImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">HR</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_11">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={FinanceImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Finance</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_12">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={AccountingImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Accounting</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StandardApps;
