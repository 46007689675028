import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.scss';
import Logo from "../../../assets/images/logo.png";
import Submit from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { loginUser, clearState } from "../../../slice/auth/authSlice";
import { useCookies } from 'react-cookie';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const AdminLogins = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { userInfo, isSuccess, isError, errorMessage } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [cookies, setCookie] = useCookies(['user']);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        document.title = "Login | DK Global ERP"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState());
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: "Login successfully" }))
            history.push("/dashboard/samplepage1");
        }
    }, [isError, isSuccess]);

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;

            case "password":
                setPassword(value)
                break;

            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!email || !password) {
            dispatch(showError({ msg: "Please fill all details" }))
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(loginUser({ email, password }))
        }
    };

    return (
        <React.Fragment>
            {/* <div className="dk-loginHeader">
                <Link to="#">
                    <img src={Logo} className="img-fluid" alt="" />
                </Link>
            </div> */}
            <div className="dk-adminLoginMain">
                <div className="dk-adminLogin">
                    <div className="dk-lofinBoxContainer">
                        <div className="dk-adminLoginLogo">
                            <img src={Logo} className="img-fluid" alt="" />
                        </div>
                        <div>
                            <form >
                                <div className="form-group">
                                    <label htmlFor="">
                                        <input type="text" placeholder="Email Address" name="email" className="form-control" onChange={handleChange}
                                            value={email} />
                                    </label>
                                    <label htmlFor="" className="dk-passwordeye">
                                        <input type={showPassword ? "text" : "password"} placeholder="Password" name="password" className="form-control" onChange={handleChange}
                                            value={password} />
                                        {/* <span><i class="fas fa-eye-slash"></i></span> */}
                                        <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </span>
                                    </label>
                                </div>
                                <div className="form-group mb-0">
                                    <label htmlFor="" className="text-center">
                                        <Submit txt="LOGIN AS ADMIN" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn" />
                                        <Link to="#" className="mt-3">Forget account password?</Link>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminLogins;
