import { createSlice } from '@reduxjs/toolkit';

const linearSlice = createSlice({
    name: "progressBar",
    initialState: {
        loading: false
    },
    reducers: {
        loadingStart: (state) => {
            state.loading = true;
        },
        loadingEnd: (state) => {
            state.loading = false;
        },
        clearLoading: (state) => {
            state.loading = false;
        }
    }
});
export const { loadingStart, loadingEnd, clearLoading } = linearSlice.actions;

export default linearSlice.reducer;