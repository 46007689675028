import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
const commentLoader = ({ colspan }) => {
    return (
        <React.Fragment>
            <li>
                <div className="comment-main-level">
                    <div className="comment-avatar">
                        <Skeleton animation="wave" variant="circle" width={44} height={44} />
                    </div>
                    <div className="comment-box">
                        <div className="comment-head d-flex align-items-center justify-content-between">
                            <span className="d-flex w-100 justify-content-between">
                                <Skeleton animation="wave" height={10} width="25%" />
                                <Skeleton animation="wave" height={10} width="25%" />
                            </span>
                            <div className="d-flex headIcons">
                                <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                            </div>
                        </div>
                        <div className="comment-content">
                            <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </div>
                    </div>
                </div>
            </li>
        </React.Fragment>
    );
}
export default commentLoader;