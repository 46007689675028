import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './style.scss';


const EditBkValuesModal = () => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Link to="#" onClick={handleShow}><i class="far fa-edit"></i></Link>
  
        <Modal className="dk-toolsBkVlueseditModal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Main Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="dk-toolsModalbody">
                <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="edit" title="Edit">
                        <div className="dk-toolsValuesPanel">
                            <div className="d-flex align-items-center">
                                <span className="dk-sno">1</span>
                                <input type="text" value="MasterBoxjavascript:void(0);" name="" id="" className="form-control" />
                                <div className="dk-toolsPanel-updateIcon">
                                    <i class="far fa-save"></i>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="dk-sno">2</span>
                                <input type="text" value="MasterBoxjavascript:void(0);" name="" id="" className="form-control" />
                                <div className="dk-toolsPanel-updateIcon">
                                    <i class="far fa-save"></i>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="dk-sno">3</span>
                                <input type="text" value="MasterBoxjavascript:void(0);" name="" id="" className="form-control" />
                                <div className="dk-toolsPanel-updateIcon">
                                    <i class="far fa-save"></i>
                                </div>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="reorder" title="Reorder">
                        <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                            <div className="d-flex align-items-center">
                                <span className="dk-sno"><i class="fas fa-arrows-alt"></i></span>
                                <input type="text" value="Masterbox" name="" id="" className="form-control" />
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="dk-sno"><i class="fas fa-arrows-alt"></i></span>
                                <input type="text" value="My Account" name="" id="" className="form-control" />
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="dk-sno"><i class="fas fa-arrows-alt"></i></span>
                                <input type="text" value="Test" name="" id="" className="form-control" />
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="dk-toolsPanelSaveBtn">Save Order</button>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="delete" title="Delete">
                    <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                            <div className="d-flex align-items-center">
                                <input type="text" value="Masterbox" name="" id="" className="form-control" />
                                <div className="dk-toolsPanel-updateIcon">
                                    <i class="far fa-trash-alt"></i>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <input type="text" value="My Account" name="" id="" className="form-control" />
                                <div className="dk-toolsPanel-updateIcon">
                                    <i class="far fa-trash-alt"></i>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <input type="text" value="Test" name="" id="" className="form-control" />
                                <div className="dk-toolsPanel-updateIcon">
                                    <i class="far fa-trash-alt"></i>
                                </div>
                            </div>

                        </div>
                    </Tab>
                </Tabs>
              </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
export default EditBkValuesModal;
