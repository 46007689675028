import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import { updateEmails, clearState } from "../../../slice/emails";
import { showSuccess, showError, clearMessage } from '../../../slice/utils/message/messageSlice';
import { loadingStart, loadingEnd, clearLoading } from "../../../slice/utils/loader/linearSlice";
import SubmitButton from "../../ui/submitButton/index";

const EditEmailsModal = ({ emailId, handleClose }) => {
    const dispatch = useDispatch();
    const { emailDetails, isUpdate, msg, isError } = useSelector(state => state.emails);
    const [rowId, setRowId] = useState(emailDetails._id);
    const [email, setEmail] = useState(emailDetails.email);
    const [url, setUrl] = useState(emailDetails.url);
    const [category, setCategory] = useState(emailDetails.category);
    const [password, setPassword] = useState(emailDetails.passcode);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [emailId])

    const submitData = (e) => {
        e.preventDefault()
        if (!rowId || !email || !url || !category || !password) {
            dispatch(showError({ msg: 'All fields are required' }));
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(updateEmails({ rowId: rowId, email: email, url: url, category: category, password: password }));
        }
    }
    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(clearLoading())
            setLoading(false);
        }
        if (isUpdate) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(clearLoading())
            dispatch(loadingEnd())
            setLoading(false);
            setShow(false)
            handleClose()
        }
    }, [isError, isUpdate])

    return (
        <>
            <Modal className="dk-toolsReorderModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-toolsModalbody">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="">Name of Email Address</label>
                                <label htmlFor="">
                                    <input type="text" value={email} name="" id="" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Category of Email</label>
                                <label htmlFor="">
                                    <input type="text" value={category} name="" id="" className="form-control" onChange={(e) => setUrl(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Response Redirecting to</label>
                                <label htmlFor="">
                                    <input type="text" value={url} name="" id="" className="form-control" onChange={(e) => setUrl(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Password</label>
                                <label htmlFor="">
                                    <input type="text" value={password} name="" id="" className="form-control" onChange={(e) => setPassword(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group mb-0">
                                <label htmlFor="" className="text-center">
                                    {/* <button>Update</button> */}
                                    <SubmitButton txt="Update" loading={loading} success={success} onClick={submitData} position="justify-content-center" className="dk-addCliBtn" />
                                </label>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditEmailsModal;
