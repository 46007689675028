import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import UserList from "./userList";
import './style.scss';
import CancelIcon from '@material-ui/icons/Cancel';
const UserToggle = ({ userFilterId, userFilterImg, list, selectUser, deleteFilter }) => {
    return (
        <React.Fragment>
            <Dropdown className="dk-filterUserTabs">
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                    {(userFilterId == '0') ? <i className="fas fa-user"></i> : <React.Fragment><img src={userFilterImg} className="table-select-user-img" /></React.Fragment>}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="d-flex">
                        <UserList list={list} onClick={selectUser} />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            {(userFilterId !== '0') ? <CancelIcon className="crossUserFilter" onClick={() => deleteFilter('mangerId')} /> : null}
        </React.Fragment>
    )
}
export default UserToggle;