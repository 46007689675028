import React from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import DanceImg from "../../../assets/images/dance.png";
import RestaurantsImg from "../../../assets/images/restaurants.png";
import SelfImg from "../../../assets/images/self.png";
import RecruitementImg from "../../../assets/images/recruitement.png";
import RetailImg from "../../../assets/images/retail.png";

const CustomisedApps = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-appsStandardMain">
                <div className="dk-container-apps">
                    <div className="dk-appsSideMenu">
                        <ul>
                            <li><Link to="/apps/live-apps" className={`${pathname.match('/apps/live-apps') ? 'active' : ''}`}>Standard Apps</Link></li>
                            <li><Link to="/apps/customised-apps" className={`${pathname.match('/apps/customised-apps') ? 'active' : ''}`}>Customised Apps</Link></li>
                        </ul>
                    </div>
                    <div className="row justify-content-start w-100">
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={RecruitementImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Recruitement</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={RestaurantsImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Restaurants</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={DanceImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Dance & Fitness</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={RetailImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Retail Business</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={SelfImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Self Employed</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CustomisedApps;
