import React from 'react';
import './style.scss';

const Accounts = () => {
    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default Accounts;
