import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';

const SitepagesSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-submMenuMain">
                <div className="dk-subMenu">
                    <Route>
                        <ul>
                            <li><Link to="/sitepages/samplepage1" className={`${pathname.match('/samplepage1') ? 'sub-active' : ''}`}>Main Site</Link></li>
                            <li><Link to="/sitepages/samplepage2" className={`${pathname.match('/samplepage2') ? 'sub-active' : ''}`}>Footer</Link></li>
                            <li><Link to="/sitepages/samplepage3" className={`${pathname.match('/samplepage3') ? 'sub-active' : ''}`}>CMS</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitepagesSubmenu;
