import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import { updateSoftware, clearState } from "../../../slice/software";
import { showSuccess, showError, clearMessage } from '../../../slice/utils/message/messageSlice';
import { loadingStart, loadingEnd, clearLoading } from "../../../slice/utils/loader/linearSlice";
import SubmitButton from "../../ui/submitButton/index";

const EditSoftwareModal = ({ softwareId, handleClose }) => {
    const dispatch = useDispatch();
    const { softwareDetails, isUpdate, msg, isError } = useSelector(state => state.software);
    const [rowId, setRowId] = useState(softwareDetails._id);
    const [department, setDepartment] = useState(softwareDetails.department);
    const [status, setStatus] = useState(softwareDetails.status);

    const [division, setDivision] = useState(softwareDetails.division);
    const [toolsName, setToolsName] = useState(softwareDetails.toolName);
    const [category, setCategory] = useState(softwareDetails.category);
    const [functionality, setFunctionality] = useState(softwareDetails.functionality);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [softwareId])

    const submitData = (e) => {
        e.preventDefault();
        if (!rowId || !division || !toolsName || !department || !category || !functionality || !status) {
            dispatch(showError({ msg: 'All fields are required' }));
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(updateSoftware({ rowId: rowId, division: division, toolName: toolsName, department: department, category: category, functionality: functionality, status: status }));
        }
    }
    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(clearLoading())
            setLoading(false);
        }
        if (isUpdate) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(clearLoading())
            dispatch(loadingEnd())
            setLoading(false);
            setShow(false)
            handleClose()
        }
    }, [isError, isUpdate])

    return (
        <>
            <Modal className="dk-toolsReorderModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-toolsModalbody">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="">Status</label>
                                <label htmlFor="">
                                    <select name="" id="" value={status} className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Department</label>
                                <label htmlFor="">
                                    <select name="" id="" className="form-control" value={department} onChange={(e) => setDepartment(e.target.value)}>
                                        <option value="IT Department">IT Department</option>
                                        <option value="Recruiter Drpartment">Recruiter Drpartment</option>
                                    </select>
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Division</label>
                                <label htmlFor="">
                                    <input type="text" value={division} name="" id="" className="form-control" onChange={(e) => setDivision(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Name of Tool</label>
                                <label htmlFor="">
                                    <input type="text" value={toolsName} name="" id="" className="form-control" onChange={(e) => setToolsName(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Category</label>
                                <label htmlFor="">
                                    <input type="text" value={category} name="" id="" className="form-control" onChange={(e) => setCategory(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Functionality</label>
                                <label htmlFor="">
                                    <input type="text" value={functionality} name="" id="" className="form-control" onChange={(e) => setFunctionality(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group mb-0">
                                <label htmlFor="" className="text-center">
                                    <SubmitButton txt="Update" loading={loading} success={success} onClick={submitData} position="justify-content-center" className="dk-addCliBtn" />
                                </label>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditSoftwareModal;
