import React from 'react';
import './style.scss';

const Dashboard = () => {
    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default Dashboard;
