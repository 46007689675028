import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate } = useSelector(state => state.auth)
    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                return <Layout><Component {...props} /></Layout>
            return <Redirect
                to={{
                    pathname: "/"
                    , state: { from: props.location }
                }}
            />
        }} />
    )
};
export default PrivateRoute;