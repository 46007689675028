import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
const TableLoader = ({ colspan }) => {
    return (
        <td colspan={colspan} style={{ padding: '5px .75rem', backgroundColor: '#eee' }}>
            <Skeleton />
            <Skeleton animation="wave" />
        </td>
    );
}
export default TableLoader;