import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import DnDList from 'react-dnd-list'
import SubmitButton from "../../ui/submitButton/index";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { emailslist } from "../../../slice/emails";

const Item = props => {
  const dnd = props.dnd
  return (
    <>
      <li style={{ ...dnd.item.styles, ...dnd.handler.styles }}
        className={"ui-state-default ui-sortable-handle" + dnd.item.classes}
        ref={dnd.item.ref}
        {...dnd.handler.listeners}
      >
        <div className="d-flex align-items-center">
          <div><i class="fas fa-arrows-alt"></i></div>
          <div>{props.item.email}</div>
        </div>
      </li>
    </>
  )
}

const EmailReorderModal = ({ showPopup, hidePopup }) => {
  const dispatch = useDispatch();
  let { emails } = useSelector(state => state.emails)
  const [list, setList] = useState(emails)
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true)
  }, [])

  const submitData = async (e) => {
    let reorderList = []
    list.map((data) => {
      reorderList.push(data._id)
    })
    const { token } = JSON.parse(localStorage.getItem("authentication"));
    await fetch('/api/emails/reorder', {
      method: 'POST',
      body: JSON.stringify({ "ids": reorderList }),
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      }
    }).then(response => {
      if (response.status === 200) {
        dispatch(showSuccess({ msg: 'Orders updated successfully' }))
        dispatch(emailslist({}))
        setShow(false)
      }
    })
  }

  return (
    <>
      <Modal className="dk-toolsReorderModal" show={show} onHide={hidePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Reorder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dk-toolsModalbody">
            <ul className="sortable">
              <DnDList
                items={list}
                itemComponent={Item}
                setList={setList}
              />
            </ul>
            <SubmitButton txt="Update" onClick={submitData} position="justify-content-center" className="dk-addCliBtn mt-4 mb-3" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EmailReorderModal;
