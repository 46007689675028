import React from 'react';
import { Route, Link } from 'react-router-dom';
import './style.scss';

const Navbar = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navbarMain">
                <div className="dk-navbarMenu">
                    <Route>
                        <ul>
                            <li><Link to="/dashboard/samplepage1" className={`${pathname.match('/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                            <li><Link to="/mailbox/samplepage1" className={`${pathname.match('/mailbox') ? 'active' : ''}`}><span className="dk-badge">0</span> Mailbox</Link></li>
                            <li><Link to="/alerts/samplepage1" className={`${pathname.match('/alerts') ? 'active' : ''}`}><span className="dk-badge red">3</span> Alerts</Link></li>
                            <li><Link to="/support/samplepage1" className={`${pathname.match('/support') ? 'active' : ''}`}><span className="dk-badge blueColor">0</span> Support</Link></li>
                            <li><Link to="/accounts/samplepage1" className={`${pathname.match('/accounts') ? 'active' : ''}`}>Accounts</Link></li>
                            <li><Link to="/apps/live-apps" className={`${pathname.match('/apps') ? 'active' : ''}`}>Apps</Link></li>
                            <li><Link to="/package/samplepage1" className={`${pathname.match('/package') ? 'active' : ''}`}>Package</Link></li>
                            <li><Link to="/billings/samplepage1" className={`${pathname.match('/billings') ? 'active' : ''}`}>Billings</Link></li>
                            <li><Link to="/sitepages/samplepage1" className={`${pathname.match('/sitepages') ? 'active' : ''}`}>Site Pages</Link></li>
                            <li><Link to="/tools/bkvalues" className={`${pathname.match('/tools') ? 'active' : ''}`}>Tools</Link></li>
                            <li><Link to="/settings/users" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
