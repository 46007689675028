import React, { Suspense, useState, useMemo, useEffect } from "react";
import TableLoader from "../../../utils/tableLoader/taskTable";
import { Link, Route } from "react-router-dom";
import NoData from "../../../utils/tableNoData";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import TablePagination from '../../../ui/pagination';
import './clients.scss';
import StatusDropdown from '../../../ui/statusDropdown';
import { companieslist, updateStatus, updateFeature, companyComments, newCompanyComment, deleteComment, clearMessage, companyCommentUpdate, deleteCompanyAccount } from "../../../../slice/companies/companySlice";
import DateFormat from '../../../utils/dateFormats/DateFormat';
import { GlobalFilter } from "../filters/GlobalFilter";
import UserFilterToggle from "../../../ui/tableUserFilter";
import { makeStyles } from '@material-ui/core/styles';
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import DeleteModal from "../../../ui/delete/DeleteModal";
import CountComment from "../../../utils/countComment";
import CommentPopup from "../../../ui/comment/index";
//import { IMG_CLOUD_BASE_URL } from "../../../../constants";
import Tooltip from "../../../ui/tooltip";
import FilterSelectInstance from '../../../ui/selectPicker/FilterSelectInstance';
import Authentication from "../../../helpers/authenticate";
const ClientConstants = require('../../../../constants')

const defaultImg = "erp/admin/users/default_user.png";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));
function Table({ loading, columns, data }) {

    const classes = useStyles();
    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')

    const [selectedOptions, setSelectedOptions] = useState('');

    const [isReset, setIsReset] = useState(false)

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('mangerId', e.target.id)
    }

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'mangerId') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["mangerId"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    const clearAllFilter = () => {
        setFilter('mangerId', '')
        setUserFilterId('0')
        setUserFilterImg('0')
        setFilter('status', '')
        setGlobalFilter('')
        setIsReset(true);
        setSelectedOptions('')
    }

    const resetIsClear = () => {
        setIsReset(false)
    }

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        setSelectedOptions(value)
        setFilter(name, value)
    }

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-clientsHead">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-clientsHead-leftPanel">
                        <div className="dk-searchCols">
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} reset={isReset} clearReset={resetIsClear} className="form-control" />
                        </div>
                        <div className="dk-clientLeft-btn dk-accountManeger">
                            <UserFilterToggle userFilterId={userFilterId} userFilterImg={userFilterImg} list={tableInstance.flatRows} selectUser={clickFilterUser} deleteFilter={deleteFilter} />
                            <Link to="#" className="filterbtn" onClick={() => clearAllFilter()}><i class="fas fa-sync-alt"></i></Link>
                        </div>
                    </div>
                    <h3 className="title">COMPANY ACCOUNTS</h3>
                    <div className="dk-clientsHead-rightPanel">
                        <div className="d-flex align-items-center">
                            <div className="dk-accountSmallBtn">
                                <Link to="#"><i class="fas fa-search"></i></Link>
                            </div>
                            <div className="dk-accountRightPanel-dropdown">
                                <FilterSelectInstance list={tableInstance.flatRows} type="status" placeholder="Status" name="status" onChange={handleChangeSelect} reset={isReset} clearReset={resetIsClear} selectedOptions={selectedOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dk-clientsTable-main">
                <div className="table-responsive">
                    <div className="dk-smsTableHead">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-smsTableHead-right">

                            </div>

                        </div>
                    </div>
                    <div className="table-reposive">
                        <table className="table table-bordered" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="18" txt="No data found..." /> : null}
                            {
                                (loading) ? <TableLoader colspan="18" /> :
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(
                                            (row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                            }
                        </table>
                    </div>
                </div>
                <div className="dk-tablePagination">
                    <div className="d-flex align-items-center justify-content-between">
                        <span>Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</span>
                        <div className={classes.root}>
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const AccountClients = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth)
    const { companies, isLoading, isUpdate, isError, isDelete, errorMessage, isCommentLoading, comments, isSuccess, msg, users } = useSelector(state => state.companies);
    const [userId, setUserId] = useState(userInfo.id);
    const [data, setData] = useState([]);
    const [rowId, setRowId] = useState('');
    const [show, setShow] = useState(false);

    /** Show Comments */
    const [showComment, setShowComment] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [btnTxt, setBtnTxt] = useState('Submit');

    const openComment = async (id) => {
        setRowId(id)
        setShowComment(true)
        await dispatch(companyComments({ companyId: id }))
    }

    const handleCloseComment = () => setShowComment(false);

    useEffect(() => {
        if (!isCommentLoading) {
            setCommentList(comments)
        }
    }, [isCommentLoading])
    /** End Show Comments */

    /** Insert Comment */
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [parentId, setParentId] = useState('0');
    const [comment, setComment] = useState('');

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "comment":
                setComment(value)
                break;

            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!comment) {
            dispatch(showError({ msg: 'Please enter comment.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                if (commentId == '0') {
                    await dispatch(newCompanyComment({ companyId: rowId, parentId: parentId, userId: userId, comment: comment }))
                } else {
                    await dispatch(companyCommentUpdate({ companyId: rowId, commentId: commentId, comment: comment }))
                }
            }
        }
    };

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            setSuccess(true);
            setLoading(false);
            dispatch(clearMessage());
        }
        if (isSuccess) {
            dispatch(showSuccess({ msg: msg }))
            setSuccess(true);
            setLoading(false);
            setComment('');
            dispatch(clearMessage());
        }

    }, [isError, isSuccess])
    /** End Insert Comment */
    const [showAccountDelete, setAccountDelete] = useState(false)
    /*Delete Comment */
    const [showDelete, setShowDelete] = useState(false)
    const [commentId, setCommentId] = useState('0')

    const deleteData = async (id) => {
        setCommentId(id)
        setShowDelete(true)
    }
    const handleCloseDelete = () => {
        setShowDelete(false)
        setAccountDelete(false)
    }
    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setAccountDelete(false)
            setCommentList(comments)
            setSuccess(true);
            setLoading(false);
            setCommentId('0');
            dispatch(clearMessage())
        }
    }, [isDelete])
    /*End Delete Comment*/

    /** Edit Comment */
    const hendleEditClick = async (id, value) => {
        setCommentId(id)
        setComment(value)
        setBtnTxt('Update')
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Comment update successfully' }))
            setBtnTxt('Submit')
            setSuccess(true);
            setLoading(false);
            setComment('')
            setCommentId('0')
            setCommentList(comments)
            dispatch(clearMessage());
        }
    }, [isUpdate])
    /** End Edit Comment */

    /** Reply Comment */
    const hendleReplyClick = async (id) => {
        setCommentId('0')
        setParentId(id)
    }
    /** End Reply Comment */

    const deleteItem = async () => {
        await dispatch(deleteComment({ id: rowId, commentId: commentId }))
    }
    const deleteRow = async () => {
        await dispatch(deleteCompanyAccount({ id: rowId }))
    }
    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(companieslist({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    const changeStatus = async (e) => {
        await dispatch(updateStatus({ rowId: e.target.id, status: e.target.dataset.key }))
    }

    const featuredChange = async (e) => {
        let isChecked = e.target.checked;
        let featured;
        if (isChecked === true) {
            featured = "ON";
        } else {
            featured = "OFF";
        }
        await dispatch(updateFeature({ rowId: e.target.dataset.row, feature: featured }))
    }

    useEffect(() => {
        if (!isLoading && companies && companies.length > -1) {
            setData(companies)
        }
        if (!isUpdate && companies && companies.length > -1) {
            setData(companies)
        }
        setData(companies)
    }, [isLoading, isUpdate, changeStatus])

    const columns = useMemo(() => [
        {
            Header: 'SNO.',
            title: 'SNO.',
            Cell: (row) => Number(row.row.id) + 1,
            accessor: 'number'
        },
        {
            Header: 'Status',
            title: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => (<>
                <StatusDropdown id={cell.row.original._id} changeStatus={changeStatus} status={cell.row.original.status} />
            </>)
        },
        {
            Header: 'Created',
            title: 'Created',
            accessor: 'createdAt',
            Cell: (row) => (<DateFormat date={row.row.original.createdAt} />)
        },
        {
            id: 'mangerId',
            Header: 'ID',
            title: 'ID',
            accessor: 'accountManager',
            show: false,
        },
        {
            Header: 'ID',
            title: 'ID',
            accessor: 'accountManager',
            Cell: (row) => {
                return (<>
                    {row.row.original.uid}
                </>
                )
            }
        },
        {
            Header: 'Name of Admin',
            title: 'Name of Admin',
            accessor: 'name',
            Cell: (row) => {
                return (<>
                    {row.row.original.name}
                </>
                )
            }
        },
        {
            Header: 'Account Email',
            title: 'Account Email',
            accessor: 'email',
            Cell: (row) => {
                return (
                    <>
                        {row.row.original.email}
                    </>
                )
            }
        },
        {
            Header: 'Admins',
            title: 'Admins',
            Cell: (row) => {
                return (
                    <>
                        {row.row.original.users.filter(findRole => findRole.role == "Admin").length}
                    </>
                )
            }
        },
        {
            Header: 'Users',
            title: 'Users',
            Cell: (row) => {
                return (
                    <>
                        {row.row.original.users.filter(findRole => findRole.role == "User").length}
                    </>
                )
            }
        },
        {
            Header: 'Account Domain',
            title: 'Account Domain',
            accessor: 'domain',
            Cell: (row) => {
                return (<>
                    {row.row.original.domain}
                </>
                )
            }
        },
        {
            Header: 'AM',
            title: 'AM',
            accessor: 'caseAdmin.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className="dk-tableUserImg">
                        {(cell.row.original.caseAdmin) ?
                            <Tooltip type="img" title={cell.row.original.caseAdmin.firstName + " " + cell.row.original.caseAdmin.lastName} value={ClientConstants.IMG_CLOUD_BASE_URL.Url + cell.row.original.caseAdmin.userImg} className="" />
                            :
                            <Tooltip type="img" title="No Account Manager selected" value={ClientConstants.IMG_CLOUD_BASE_URL.Url + defaultImg} className="" />}
                    </div>
                )
            }
        },
        {
            Header: 'Comments',
            title: 'Comments',
            Cell: (cell) => {
                return (
                    <>
                        <div className="dk-comments" onClick={() => openComment(cell.row.original._id)}><span><CountComment comment={cell.row.original.comments} /></span></div>
                    </>
                )
            }
        },
        {
            Header: 'Name of Business',
            title: 'Name of Business',
            accessor: 'businessName',
            Cell: (row) => {
                return (<>
                    {row.row.original.businessName}
                </>
                )
            }
        },
        {
            Header: 'Business Type',
            title: 'Business Type',
            accessor: 'businessType',
            Cell: (row) => {
                return (<>
                    {row.row.original.businessType}
                </>
                )
            }
        },
        {
            Header: 'Industry',
            title: 'Industry',
            accessor: 'industry',
            Cell: (row) => {
                return (<>
                    {row.row.original.industry}
                </>
                )
            }
        },
        {
            Header: 'City/Town',
            title: 'City/Town',
            accessor: 'city',
            Cell: (row) => {
                return (<>
                    {row.row.original.city}
                </>
                )
            }
        },
        {
            Header: 'Country',
            title: 'Country',
            accessor: 'country',
            Cell: (row) => {
                return (<>
                    {row.row.original.country}
                </>
                )
            }
        },
        {
            Header: 'Featured',
            title: 'Featured',
            accessor: 'feature',
            Cell: ({ cell }) => {
                return (<>
                    <div className="dk-featuredSwitch">
                        <span>OFF</span>
                        {
                            (cell.row.original.feature === 'ON') ? <input type="checkbox" id={"switch" + cell.row.original._id} onChange={featuredChange} data-row={cell.row.original._id} checked /> : <input type="checkbox" id={"switch" + cell.row.original._id} onChange={featuredChange} data-row={cell.row.original._id} />
                        }
                        <label for={"switch" + cell.row.original._id}>Toggle</label>
                        <span>ON</span>
                    </div>
                </>
                )
            }
        },
        {
            Header: 'Action',
            title: 'Action',
            Cell: ({ cell }) => {
                return (<>
                    <div className="dk-actionBtn">
                        <Route>
                            <Link to="#" className="dk-viewBtn"><i class="fas fa-external-link-alt"></i></Link>
                            <Link to="#" className="dk-editBtn"><i class="far fa-edit"></i></Link>
                            <Link to="#" className="dk-deleteBtn" onClick={() => deleteAccount(cell.row.original._id)}><i class="far fa-trash-alt"></i></Link>
                        </Route>
                    </div>
                </>
                )
            }
        }
    ], [])

    const deleteAccount = (id) => {
        setRowId(id)
        setAccountDelete(true)
    }

    return (
        <React.Fragment>
            {
                (showAccountDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteRow} /> : null
            }
            {
                (showDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteItem} /> : null
            }

            {
                (showComment) ? <CommentPopup headingTxt={'Company'} loading={loading} success={success} id={rowId} handleClose={handleCloseComment} isCommentLoading={isCommentLoading} comments={commentList} handleSubmit={handleButtonClick} handleChange={handleChange} value={comment} handleClickDelete={deleteData} hendleEditClick={hendleEditClick} hendleReplyClick={hendleReplyClick} btnTxt={btnTxt} /> : null
            }

            <div className="dk-clientsMain">
                <Table
                    data={data}
                    columns={columns}
                    loading={isLoading}
                />
            </div>
        </React.Fragment>
    );
}

export default AccountClients;
