import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';
export const GlobalFilter = ({ filter, setFilter, reset, clearReset }) => {

    const [value, setValue] = useState(filter);

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 300)

    useEffect(() => {
        if (reset) {
            setValue(undefined)
            clearReset()
        }
    }, [reset])

    return (
        <>
            <input type="search" placeholder="Search..." className="form-control" value={value || ''} onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value)
            }} />
        </>
    )
}