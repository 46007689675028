import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
const DepartmentDropdown = ({ id, changeDepartment, department }) => {
    let statusClass;
    switch (department) {
        case 'Recruiter Drpartment':
            statusClass = 'success';
            break;
        case 'IT Department':
            statusClass = 'danger';
            break;
        default:
            statusClass = 'warning';
            break;
    }
    return (
        <React.Fragment>
            <Dropdown className="dk-roleDropdown">
                <Dropdown.Toggle variant={statusClass} id="dropdown-basic">
                    <span>{department}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item id={id} onClick={changeDepartment} data-key='IT Department' >IT Department</Dropdown.Item>
                    <Dropdown.Item id={id} onClick={changeDepartment} data-key='Recruiter Drpartment' >Recruiter Drpartment</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default DepartmentDropdown;