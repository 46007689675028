import React from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';

const ToolsSubmenu = () => {
    let pathname = window.location.pathname;
    return(
        <React.Fragment>
            <div className="dk-submMenuMain">
                <div className="dk-subMenu">
                    <Route>
                        <ul>
                            <li><Link to="/tools/bkvalues" className={`${pathname.match('/tools/bkvalues') ? 'sub-active' : ''}`}>BK Values</Link></li>
                            <li><Link to="/tools/logins" className={`${pathname.match('/tools/logins') ? 'sub-active' : ''}`}>Logins</Link></li>
                            <li><Link to="/tools/emails" className={`${pathname.match('tools/emails') ? 'sub-active' : ''}`}>Emails</Link></li>
                            <li><Link to="/tools/software" className={`${pathname.match('tools/software') ? 'sub-active' : ''}`}>Software</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ToolsSubmenu;
