import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { clearMessage } from "../../../slice/utils/message/messageSlice";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function SuccessSnackbar() {
    const classes = useStyles();
    const { isSuccess, message } = useSelector(state => state.alertMessage);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearMessage())
    }, [dispatch])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(clearMessage());
    };

    return (
        <div className={classes.root}>
            {
                isSuccess ? <Snackbar open={true} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {
                            message
                        }
                    </Alert>
                </Snackbar> : null
            }
        </div>
    );
}