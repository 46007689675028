import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const loginslist = createAsyncThunk(
    'logins/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/logins/list/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateLogins = createAsyncThunk(
    'logins/update',
    async ({ rowId, platformName, url, userName, password }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/logins/update/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        rowId, platformName, url, userName, password
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteLogins = createAsyncThunk(
    'logins/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/logins/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const newLogins = createAsyncThunk(
    'logins/add',
    async ({ platformName, url, userName, password }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/logins',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        platformName, url, userName, password
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const detailsById = createAsyncThunk(
    'logins/details',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/logins/details/' + rowId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const loginsSlice = createSlice({
    name: 'toolsLogin',
    initialState: {
        logins: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        isInsert: false,
        errorMessage: '',
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.msg = '';
            state.loginDetails = [];
            return state;

        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
        }
    },
    extraReducers: {
        [loginslist.pending]: (state) => {
            state.isLoading = true;
        },
        [loginslist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.logins = payload.logins;
        },
        [loginslist.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
        [updateLogins.pending]: (state) => {
        },
        [updateLogins.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.logins;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.logins = finalResult
            state.msg = payload.message.msgBody

        },
        [updateLogins.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = true;
            // state.msg = payload.message.msgBody
        },
        [deleteLogins.pending]: (state) => {
        },
        [deleteLogins.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.logins = state.logins.filter((data) => data._id !== action.meta.arg.id)
            state.msg = 'Delete Successfully';
        },
        [deleteLogins.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [newLogins.pending]: (state) => {

        },
        [newLogins.fulfilled]: (state, { payload }) => {
            state.logins.push(payload.details);
            state.isInsert = true;
            state.msg = payload.message.msgBody
        },
        [newLogins.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [detailsById.pending]: (state) => {
        },
        [detailsById.fulfilled]: (state, { payload }) => {
            state.loginDetails = payload.details;
        },
        [detailsById.rejected]: (state, { payload }) => {
        }
    }
})
export const { clearState, clearMessage } = loginsSlice.actions
export default loginsSlice.reducer;