import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const companieslist = createAsyncThunk(
    'companies/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/list/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateStatus = createAsyncThunk(
    'companies/changeStatus',
    async ({ rowId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/updateStatus/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateFeature = createAsyncThunk(
    'companies/changeFeature',
    async ({ rowId, feature }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/updateFeature/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        feature
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const companyComments = createAsyncThunk(
    'companies/comments',
    async ({ companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/comment/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const newCompanyComment = createAsyncThunk(
    'companies/new/comment',
    async ({ companyId, parentId, userId, comment }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/comment/' + companyId,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        parentId,
                        userId,
                        comment
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const deleteComment = createAsyncThunk(
    'companies/delete/comment',
    async ({ id, commentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/comment/' + id + '/' + commentId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 202) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const deleteCompanyAccount = createAsyncThunk(
    'companies/delete/account',
    async ({ id, }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const companyCommentUpdate = createAsyncThunk(
    'companies/update/comment',
    async ({ companyId, commentId, comment }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/companies/comment/' + companyId + '/' + commentId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        comment
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);


const companiesSlice = createSlice({
    name: 'companies',
    initialState: {
        companies: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        isInsert: false,
        orinalComment: [],
        comments: [],
        errorMessage: '',
        isCommentLoading: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isCommentLoading = false;
            state.msg = '';
            return state;

        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
        }
    },
    extraReducers: {
        [companieslist.pending]: (state) => {
            state.isLoading = true;
        },
        [companieslist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.companies = payload.companyList;
        },
        [companieslist.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
        [updateStatus.pending]: (state) => {
        },
        [updateStatus.fulfilled]: (state, action) => {
            let mainArray = state.companies;
            mainArray.map((r) => {
                if (r._id === action.meta.arg.rowId) {
                    return (r.status = action.meta.arg.status)
                } else {
                    return r
                }
            })
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateFeature.pending]: (state) => {
        },
        [updateFeature.fulfilled]: (state, action) => {
            let mainArray = state.companies;
        },
        [updateFeature.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [companyComments.pending]: (state) => {
            state.isCommentLoading = true;
        },
        [companyComments.fulfilled]: (state, { payload }) => {
            state.isCommentLoading = false;
            state.orinalComment = payload.comments;
            state.comments = payload.comments;
            state.comments = getSubItems(state.comments)
        },
        [companyComments.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [newCompanyComment.pending]: (state) => {
            state.isCommentLoading = true;
        },
        [newCompanyComment.fulfilled]: (state, { payload }) => {
            state.isCommentLoading = false;
            state.comments = payload.comments;
            state.orinalComment = payload.comments;
            state.comments = getSubItems(state.comments)
            let mainArray;
            let finalResult;
            mainArray = state.companies;
            finalResult = mainArray.map((r) => {
                if (r._id === payload.id) {
                    return ({ ...r, comments: payload.comments })
                } else {
                    return r
                }
            })
            state.companies = finalResult
            state.isSuccess = true;
            state.msg = payload.message.msgBody;
        },
        [newCompanyComment.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [deleteComment.pending]: (state) => {
            state.isDelete = false;
        },
        [deleteComment.fulfilled]: (state, { payload }) => {
            state.isDelete = true;
            state.msg = payload.message.msgBody;
            state.comments = payload.comments;
            state.comments = state.comments.filter((item) => item._id !== payload.commentId)
            state.orinalComment = state.comments;
            state.comments = getSubItems(state.comments)
            let mainArray;
            let finalResult;
            mainArray = state.companies;
            finalResult = mainArray.map((r) => {
                if (r._id === payload.id) {
                    return ({ ...r, comments: payload.comments })
                } else {
                    return r
                }
            })
            state.companies = finalResult
            state.isSuccess = true;
            state.msg = payload.message.msgBody;
        },
        [deleteComment.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [companyCommentUpdate.pending]: (state) => {
        },
        [companyCommentUpdate.fulfilled]: (state, action) => {
            state.isUpdate = true;
            let mainArray = state.orinalComment;
            let finalResult = mainArray.map((r) => {
                if (r._id === action.meta.arg.commentId) {
                    return ({ ...r, comment: action.meta.arg.comment })
                } else {
                    return r
                }
            })
            state.orinalComment = finalResult
            state.comments = getSubItems(state.orinalComment)
        },
        [companyCommentUpdate.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [deleteCompanyAccount.pending]: (state) => {
        },
        [deleteCompanyAccount.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.companies = state.companies.filter((company) => company._id !== action.meta.arg.id)
            state.msg = 'Delete Successfully';
        },
        [deleteCompanyAccount.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },

    }
})
function getSubItems(categories, parentId = '0') {
    const itemsList = []
    let item;
    if (parentId == '0') {
        item = categories.filter(cat => cat.parentId == '0')
    } else {
        item = categories.filter(cat => cat.parentId == parentId)
    }
    for (let cate of item) {
        itemsList.push({
            _id: cate._id,
            parentId: cate.parentId,
            comment: cate.comment,
            createdOn: cate.createdOn,
            updatedOn: cate.updatedOn,
            userId: cate.userId,
            children: getSubItems(categories, cate._id)
        })
    }
    return itemsList;
}
export const { countBadges, clearState, clearMessage } = companiesSlice.actions
export default companiesSlice.reducer;