import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const industriesList = createAsyncThunk(
    'industries/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backendvalues/categoryValues/industries',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const hereAboutList = createAsyncThunk(
    'hereAbout/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backendvalues/categoryValues/hereAbout',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const businessList = createAsyncThunk(
    'business/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backendvalues/categoryValues/businessType',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const bkvaluesList = createAsyncThunk(
    'bkvalues/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/bkvalues',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addCategory = createAsyncThunk(
    'bkvalues/add',
    async ({ name, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/bkvalues',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        name, parentId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const subCategory = createAsyncThunk(
    'bkvalues/subCatList',
    async ({ categoryId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/bkvalues/subcategory' + '/' + categoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const subCategory2nd = createAsyncThunk(
    'bkvalues/subCate2ndList',
    async ({ categoryId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/bkvalues/subcategory' + '/' + categoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const subCategory3rd = createAsyncThunk(
    'bkvalues/subCate3rdList',
    async ({ categoryId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/bkvalues/subcategory' + '/' + categoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const subCategory4th = createAsyncThunk(
    'bkvalues/subCate3rdList',
    async ({ categoryId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/bkvalues/subcategory' + '/' + categoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const backendValueSlice = createSlice({
    name: 'backendValues',
    initialState: {
        industries: [],
        businessType: [],
        hereAboutUs: [],
        bkvalueList: [],
        addMainCategory: [],
        subcategory1stList: [],
        subcategory3rdList: [],
        mainCatId: 0,
        firstSubCategory: 0,
        secondSubCategory: 0,
        thirdSubCategory: 0,
        isSuccess: false,
        isError: false,
        isLoading: false,
        isInsert: false,
        msg: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.msg = '';
            state.isInsert = false
            state.subcategory1stList = [];
            state.subcategory3rdList = [];
            return state;
        }
    },
    extraReducers: {
        [industriesList.pending]: (state) => {
            state.isLoading = true;
        },
        [industriesList.fulfilled]: (state, { payload }) => {
            state.industries = payload.industriesList;
        },
        [industriesList.rejected]: (state, { payload }) => {
            // state.isError = payload.message.msgError;
            // state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [businessList.pending]: (state) => {
            state.isLoading = true;
        },
        [businessList.fulfilled]: (state, { payload }) => {
            state.businessType = payload.businessList;
        },
        [businessList.rejected]: (state, { payload }) => {
            //  state.isError = payload.message.msgError;
            // state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [hereAboutList.pending]: (state) => {
            state.isLoading = true;
        },
        [hereAboutList.fulfilled]: (state, { payload }) => {
            state.hereAboutUs = payload.hereAbout;
        },
        [hereAboutList.rejected]: (state, { payload }) => {
            //  state.isError = payload.message.msgError;
            // state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [bkvaluesList.pending]: (state) => {
            state.isLoading = true;
        },
        [bkvaluesList.fulfilled]: (state, { payload }) => {
            state.bkvalueList = payload.itemsList;
            state.mainCatId = payload.itemsList[0]._id;
        },
        [bkvaluesList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [addCategory.pending]: (state) => {
            state.isLoading = true;
        },
        [addCategory.fulfilled]: (state, { payload }) => {
            state.addMainCategory = payload.catgory;
            state.isLoading = false;
            state.isInsert = true;
            state.bkvalueList.push(payload.catgory)
            state.msg = payload.message.msgBody
        },
        [addCategory.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [subCategory.pending]: (state) => {
            state.isLoading = true;
        },
        [subCategory.fulfilled]: (state, { payload }) => {
            state.subcategory1stList = payload.result;
            if (payload.result.length > 0) {
                state.firstSubCategory = payload.result[0]._id
            } else {
                state.subcategory2ndList = []
                state.subcategory3rdList = []
                state.secondSubCategory = 0
            }
        },
        [subCategory.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [subCategory2nd.pending]: (state) => {
            state.isLoading = true;
        },
        [subCategory2nd.fulfilled]: (state, { payload }) => {
            state.subcategory2ndList = payload.result;
            if (payload.result.length > 0) {
                state.secondSubCategory = payload.result[0]._id
            }
        },
        [subCategory2nd.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [subCategory3rd.pending]: (state) => {
            state.isLoading = true;
        },
        [subCategory3rd.fulfilled]: (state, { payload }) => {
            state.subcategory3rdList = payload.result;
            if (payload.result.length > 0) {
                state.thirdSubCategory = payload.result[0]._id
            }
        },
        [subCategory3rd.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
        [subCategory4th.pending]: (state) => {
            state.isLoading = true;
        },
        [subCategory4th.fulfilled]: (state, { payload }) => {
            state.subcategory3rdList = payload.result;
            //state.thirdSubCategory = payload.result[0]._id
        },
        [subCategory4th.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
            state.isLoading = false;
        },
    }
});
export const { clearState } = backendValueSlice.actions;
export default backendValueSlice.reducer;