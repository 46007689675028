import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Redirect, Route, useParams } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Dropdown } from 'semantic-ui-react';
import './addclient.scss';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../../slice/utils/loader/linearSlice";
import { updateTempClient, clearState } from "../../../../slice/addClientTemp";
import { industriesList, businessList } from "../../../../slice/backendValues";
import BackendValuesDropdown from '../../../ui/backendValuesDropdown';
import Authentication from "../../../helpers/authenticate";
import SubmitButton from "../../../ui/submitButton/SubmitbtnArrow";

const StepTwo = () => {
    const dispatch = useDispatch();
    const { isSuccess, isInsert, isLoading, isError, msg, tempClientInfo, isUpdate } = useSelector(state => state.tempClient)
    const { industries, businessType } = useSelector(state => state.backendValues)
    let { id } = useParams();
    const [nextPage, setNextPage] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');

    const [industriesValue, setSelectedOptions] = useState('');
    const [businessTypeValue, setBusinessTypeSelectedOptions] = useState('');
    const [employees, setEmployees] = useState('');
    const [isReset, setIsReset] = useState(false)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const allbusinessType = async () => {
            await dispatch(businessList({}))
        }
        allbusinessType();
    }, [])

    useEffect(() => {
        const allIndustry = async () => {
            await dispatch(industriesList({}))
        }
        allIndustry();
    }, [])

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(clearLoading())
        }
        if (isInsert) {
            dispatch(loadingEnd())
            dispatch(clearState())
            setNextPage(true)
            dispatch(clearLoading())
        }
    }, [isError, isInsert])
    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        setSelectedOptions(value)
    }
    const businessTypeSelect = (event, data) => {
        const { name, value } = data
        setBusinessTypeSelectedOptions(value)
    }
    const resetIsClear = () => {
        setIsReset(false)
    }
    const employeesFunction = (e, data) => {
        setEmployees(data.value);
    }

    const submitData = (e) => {
        e.preventDefault()

        if (!businessName || !city || !country || !industriesValue || !businessTypeValue || !employees) {
            dispatch(loadingEnd())
            dispatch(showError({ msg: 'All fields required' }));
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(loadingStart())
            dispatch(updateTempClient({ id: id, industry: industriesValue, businessType: businessTypeValue, employees: employees, businessName: businessName, city: city, country: country }));
        }
    }
    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(loadingEnd())
            // dispatch(clearState())
            // dispatch(clearLoading())
        }
        if (isUpdate) {
            dispatch(loadingEnd())
            dispatch(clearState())
            setNextPage(true)
        }
    }, [isError, isUpdate])

    return (
        <React.Fragment>

            <Authentication />

            {nextPage ? <Redirect to={"/accounts/add-client/step-3/" + id} /> : ""}

            <div className="dk-addClientMain">
                <div className="dk-signupMain">
                    <div className="d-signUpFlex">
                        <h3 className="title mb-3">BUSINESS DETAILS</h3>
                        <div className="dk-signupContainer">
                            <form action="">
                                <div className="form-group">
                                    <label htmlFor="">Name of Business</label>
                                    <label htmlFor="">
                                        <input type="text" placeholder="Your Business Name" name="" id="" className="form-control" onChange={(e) => setBusinessName(e.target.value)} value={businessName} />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="">Business Industry</label>
                                    <label htmlFor="">
                                        <BackendValuesDropdown list={industries} placeholder="Select" name="" onChange={handleChangeSelect} reset={isReset} clearReset={resetIsClear} selectedOptions={industriesValue} />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Business Type</label>
                                    <label htmlFor="">
                                        <BackendValuesDropdown list={businessType} placeholder="Select" name="" onChange={businessTypeSelect} reset={isReset} clearReset={resetIsClear} selectedOptions={businessTypeValue} />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">No. of Employees</label>
                                    <label htmlFor="">
                                        <Dropdown
                                            placeholder='Select'
                                            fluid
                                            selection
                                            options={employeOption}
                                            onChange={employeesFunction}
                                        />
                                    </label>
                                </div>

                                <div className="d-flex">
                                    <div className="form-group mr-1">
                                        <label htmlFor="" className="text-center">City/Town</label>
                                        <label htmlFor="">
                                            <input type="text" placeholder="Enter your City or Town" name="" id="" className="form-control" onChange={(e) => setCity(e.target.value)} value={city} />
                                        </label>
                                    </div>
                                    <div className="form-group ml-1">
                                        <label htmlFor="" className="text-center">Country</label>
                                        <label htmlFor="">
                                            <input type="text" placeholder="Enter your Country" name="" id="" className="form-control" onChange={(e) => setCountry(e.target.value)} value={country} />
                                        </label>
                                    </div>
                                </div>

                                <div className="text-center dk-submitBtn mt-4">
                                    <Route>
                                        <div className="d-flex dk-addCliBtnCon">
                                            <SubmitButton txt="Next" loading={loading} success={success} onClick={submitData} position="justify-content-center" className="dk-addCliBtn" />
                                        </div>
                                    </Route>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const industryOption = [
    {
        key: 'Select',
        text: 'Select',
        value: 'Select',
    },
    {
        key: 'IT',
        text: 'IT',
        value: 'IT',
    },

]

const employeOption = [
    {
        key: '0-5',
        text: '0-5',
        value: '0-5',
    },
    {
        key: '5-10',
        text: '5-10',
        value: '5-10',
    },
    {
        key: '10-50',
        text: '10-50',
        value: '10-50',
    },
    {
        key: '50+',
        text: '50+',
        value: '50+',
    },
]
export default StepTwo;
