import React from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import RecruitementImg from "../../../assets/images/recruitement.png";
import AdvertismentImg from "../../../assets/images/advertisment.png";
import InfoCenterImg from "../../../assets/images/infocenter.png";
import LeftSideMenu from './LeftSideMenu';

const CustomApps = () => {
    return (
        <React.Fragment>
            <div className="dk-appsStandardMain">
                <div className="dk-container-apps">
                    <div className="dk-appsSideMenu">
                        <LeftSideMenu />
                    </div>
                    <div className="row justify-content-center w-100">
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={RecruitementImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Recruitement</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={AdvertismentImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Advertisment</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-appsStandardCols dk_appsBgCol_custom">
                                <Route>
                                    <Link to="#">
                                        <div>
                                            <div className="dk-appsColsIcon">
                                                <img src={InfoCenterImg} className="img-fluid" alt="" />
                                            </div>
                                            <div className="title">Knowledge Base</div>
                                        </div>
                                    </Link>
                                </Route>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CustomApps;
