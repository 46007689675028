import React from 'react';
import './style.scss';
import Pagination from '@material-ui/lab/Pagination';

const TablePagination = ({ count, onChange }) => {
    return (
        <React.Fragment>
            <Pagination count={count} shape="rounded" onChange={onChange} />
        </React.Fragment>
    )
}
export default TablePagination;
