import React from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import LeftSideMenu from './LeftSideMenu';

const Restaurants = () => {
    return (
        <React.Fragment>
            <div className="dk-appsStandardMain">
                <div className="dk-container-apps">
                    <div className="dk-appsSideMenu">
                        <LeftSideMenu />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Restaurants;
