import React, { useState, useEffect } from 'react';
import { Link, Redirect, Route } from "react-router-dom";
import './addclient.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../../slice/utils/loader/linearSlice";
import { addTempClient, clearState } from "../../../../slice/addClientTemp";
import Authentication from "../../../helpers/authenticate";
import SubmitButton from "../../../ui/submitButton/SubmitbtnArrow";


const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        This is going to be your common business email address to identify your account,. All user must fill this email before login.
    </Tooltip>
);
const domainTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        This will be a dedicated url for your business only. It will be like your own private software in cloud
    </Tooltip>
);

const StepOne = () => {
    const dispatch = useDispatch();
    const { isSuccess, isInsert, isLoading, isError, msg, tempClientInfo } = useSelector(state => state.tempClient)

    const [nextPage, setNextPage] = useState(false);
    const [email, setEmail] = useState('');
    const [domain, setDomain] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const submitData = (e) => {
        e.preventDefault()
        if (!email || !domain) {
            dispatch(loadingEnd())
            dispatch(showError({ msg: 'All fields required' }));
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(loadingStart())
            dispatch(addTempClient({ email, domain }));
        }
    }
    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(loadingEnd())
            dispatch(clearState())
            dispatch(clearLoading())
        }
        if (isInsert) {
            dispatch(loadingEnd())
            dispatch(clearState())
            setNextPage(true)
            dispatch(clearLoading())
        }
    }, [isError, isInsert])
    return (
        <React.Fragment>
            <Authentication />
            {nextPage ? <Redirect to={"/accounts/add-client/step-2/" + tempClientInfo._id} /> : ""}

            <div className="dk-addClientMain">
                <div className="dk-signupMain">
                    <div className="d-signUpFlex">
                        <h3 className="title text-center mb-1">WELCOME TO DK GLOBAL ERP</h3>
                        <h4 className="text-center">Lets get started with three simple steps</h4>
                        <div className="dk-signupContainer">
                            <form action="">
                                <div className="form-group mb-4">
                                    <label htmlFor="" className="loginNext">Account Email</label>
                                    <label htmlFor="" className="dk-infoDesc">
                                        <input type="text" placeholder="Common Account Email" name="email" className="form-control m-auto" autofocus="true" onChange={(e) => setEmail(e.target.value)} value={email} />
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                        >
                                            <span><i class="fas fa-info"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="loginNext">Account Domain</label>
                                    <div className="d-flex align-items-center">
                                        <input type="text" placeholder="e.g. mybusinessname" name="domain" className="form-control right-no-radius" style={{ backgroundColor: '#fff' }} autofocus="true" onChange={(e) => setDomain(e.target.value)} value={domain} />
                                        <span className="domainAppendSpan dk-stepOneDomain">
                                            .dkglobalerp.com
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={domainTooltip}
                                            >
                                                <i class="fas fa-info"></i>
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                </div>
                                <div className="text-center dk-submitBtn mt-4">
                                    <Route>
                                        <div className="d-flex dk-addCliBtnCon">
                                            <SubmitButton txt="Next" loading={loading} success={success} onClick={submitData} position="justify-content-center" className="dk-addCliBtn" />
                                        </div>
                                    </Route>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StepOne;
